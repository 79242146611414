import styled from "styled-components";

export const FollowSt = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  padding: 0 40px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f4ec;
  border-top: 1px solid #000;

  @media (max-width: 768px) {
    padding: 0 20px;
    height: 60px;
  }
`;

export const HeaderTitle = styled.div`
  font: normal normal normal 30px Thasadith;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #F6F1EE;
  justify-content: space-between;
`;

export const ItemFollow = styled.div`
  display: flex;
  width: 25%;
  box-sizing: border-box;
  padding: 10px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

export const ItemFollowRes = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImgFollow = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
`;