import React, {useEffect } from "react";
import { BackgroundImage, ShopNow, Title, TitleDiv } from "./styles";
import { useNavigate } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";
const SectionSingleHome = () => {
  const [image1] = useImageProgressive("assets/imgs/downgrade/deitada_modelo.jpg"    , "assets/imgs/deitada_modelo.jpg");
  
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };
  useEffect(() => {
    const shopNowButton = document.getElementById("shopNowButton");
    shopNowButton.addEventListener("click", scrollToTop);

    return () => {
      shopNowButton.removeEventListener("click", scrollToTop);
    };
  }, []); 

  return (
    <BackgroundImage
      border
      image={image1}
    >
      <TitleDiv>
        <Title style={{color:"white"}}>VESTIDOS</Title>
        <br />
        <ShopNow style={{color:"white"}} id="shopNowButton"
      onClick={ () => navigate({
        pathname: '/shop',
        search: `?search=VESTIDOS&categorylist=true`,
      })}
        >SHOP NOW</ShopNow>
      </TitleDiv>
    </BackgroundImage>
  );
};

export default SectionSingleHome;
