import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
`;

export const Image2 = styled.div`
  width: 50%;
  ${({ borderRight }) => (borderRight ? "border-right: 1px solid #000" : "")}

  @media (max-width: 768px) {
    display:none;
  }
`;

export const ImageContent = styled.div`
  @media (max-width: 768px) {
    display:none;
  }
  width: 50%;
  ${({ borderRight }) => (borderRight ? "border-right: 1px solid #000" : "")}
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 700px;
  background-color: #f7f4ec;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-color:rgb(230, 227, 228);
  background-position: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
    height: 600px;
    background-position: 50%;
  }
`;

export const BackgroundImageContent = styled.div`
  width: 100%;
  height: 700px;
  background-color: rgb(229, 227, 228);
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BackgroundImageShoes = styled.div`
  width: 100%;
  height: 700px;
  background-color: rgb(229, 227, 228);
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  img{
    transform: rotate(90deg);
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: 600px;
   background-color: #f7f4ec;

  }
`;

export const BackgroundImageSell = styled.div`
  width: 100%;
  height: 700px;
  background-color: #f7f4ec;
  background-image: url(${(props) => props.image});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: -40px 25%;
  position: relative;

@media (max-width: 1200px) {
    background-size: 110%;
    background-position: -100px 40%;
  }
  @media (max-width: 500px) {
    width: 100vw;
    height: 600px;
    background-size: 140%;
    background-position: -100px 30%;
  }
  @media (max-width: 768px) {
    width: 100vw;
    height: 600px;
    background-size: 90%;
    background-position: -100px 30%;
  }
`;

export const TitleDiv = styled.div`
  position: absolute;
  top: 20px;
  margin-left: 30px;
  color:white;

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30%;
    margin-left: 0;
    gap: 10px;
  }
`;

export const ContentAmeba = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
`;

export const ContentLeft = styled.div`
  background-color: #f7f4ec;
  width:50%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width:100%;
    justify-content: center;
  align-items: center;
  padding-top:30px;
  padding-bottom:30px;
  }

`;
export const TitleDivSell = styled.div`
  bottom: 20px;
  margin-left: 30px;
  width:100%;
`;

export const Title = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font: normal normal normal 36px ${(props) => props.theme.fontTitle.family};

  @media (max-width: 768px) {
    font: normal normal normal 30px ${(props) => props.theme.fontTitle.family};
  }
`;

export const TitleSell = styled.span`
  position: absolute;
  font: normal normal normal 36px ${(props) => props.theme.fontTitle.family};
   @media (max-width: 1200px) {
    font: normal normal normal 24px ${(props) => props.theme.fontTitle.family};
  }
`;

export const Text = styled.p`
  text-align: left;
  width: 60%;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 300;
  @media (max-width: 1200px) {
    font-size: 16px;
    width: 80%;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    width: 80%;
  }
`;

export const ShopNow = styled.span`
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SectionCookie = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;
