import React, { useEffect, useState } from "react";
import {
  FollowSt,
  Header,
  HeaderTitle,
  Content,
  ItemFollow,
  ImgFollow,
} from "./styles";
import configs from "config";
import axios from "axios";
const Follow = () => {
  const [images, setImages] = useState([[]]);
  useEffect(() => {
    const fetchProduct = async () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      try {
        const response = await axios.get(`${configs.api}instagram/getUserInfo`);
        setImages(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, []);
  return (
    <FollowSt>
      <Header>
        <HeaderTitle>FOLLOW</HeaderTitle>
        <HeaderTitle> <a
          style={{
            textDecoration: "none",
            fontSize: "18px",
            color: "black",
            '&:hover': {
              textDecoration: 'none',
              color: 'black'
            },
            '&:visited': {
              textDecoration: 'none',
              color: 'black'
            }
          }}
          href="https://www.instagram.com/takeme.online/"
        >@takemeonline</a></HeaderTitle>
      </Header>
      <Content>
        {images.map((image, index) => (
          <ItemFollow borderRight key={index}>
            <ImgFollow>
              <a href="https://www.instagram.com/takeme.online/"><img alt="" src={image.media_url} /></a>
            </ImgFollow>
          </ItemFollow>
        ))}
      </Content>
    </FollowSt>
  );
};

export default Follow;
