import {
  Content,
  Menu,
  MenuItem,
  PageContainer,
  Section,
  Text,
  Title,
} from "./styles";
import Layout from "../Layouts/Layout";
import Follow from "../../components/Follow";
import { useState } from "react";
import Button from "components/Button";
import { Link } from "react-router-dom";

const Termos = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveMenuItem(id);
    }
  };

  return (
    <Layout>
      <PageContainer border>
        <Button>
          <Link
            to="https://wa.me/message/APZHBOCKQT5SE1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#000" }}
            as="div" // Renderiza o Link como uma div
          >
            AGENDE UM HORÁRIO
          </Link>
        </Button>
        <Menu>
          {/* <MenuItem
            onClick={() => scrollToSection("section1")}
            isActive={activeMenuItem === "section1"}
          >
            Notas Legais
          </MenuItem>
          <MenuItem
            onClick={() => scrollToSection("section2")}
            isActive={activeMenuItem === "section2"}
          >
            Termos Gerais
          </MenuItem> */}
          <MenuItem
            onClick={() => scrollToSection("section3")}
            isActive={activeMenuItem === "section3"}
          >
            Política de Privacidade
          </MenuItem>
          {/* <MenuItem
            onClick={() => scrollToSection("section4")}
            isActive={activeMenuItem === "section4"}
          >
            Entregas e Retiradas
          </MenuItem>
          <MenuItem
            onClick={() => scrollToSection("section5")}
            isActive={activeMenuItem === "section5"}
          >
            Métodos de Pagamento
          </MenuItem> */}
        </Menu>
        <Content>
          {/* <Section id="section1">
            <Title>Notas Legais</Title>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
          </Section>
          <Section id="section2">
            <Title>Termos Gerais</Title>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
          </Section> */}
          <Section id="section3">
            <Title>Política de Privacidade</Title>
            <Text>
              A sua privacidade é importante para nós. Temos a política de
              respeitar a sua privacidade em relação a qualquer informação sua
              que possamos coletar em nossa plataforma TAKE ME. ONLINE e
              qualquer outra plataforma que possamos operar. Solicitamos
              informações pessoais apenas quando realmente precisamos delas para
              lhe fornecer um serviço, por meios justos e legais, com seu
              conhecimento e consentimento. Também informamos por que estamos
              coletando e como será usado.
            </Text>
            <Text>
              Retemos as informações coletadas pelo tempo necessário para
              fornecer o serviço solicitado. Quando armazenamos dados,
              protegemos dentro de meios comercialmente aceitáveis ​​para evitar
              perdas e roubos, bem como acesso, divulgação, cópia, uso ou
              modificação não autorizados. Não compartilhamos informações de
              identificação pessoal publicamente, exceto quando exigido por lei.
            </Text>
            <Text>
              A TAKE ME poderá compartilhar os dados coletados com agências de
              marketing, parceiros comerciais, gateway de pagamentos, entre
              outros com a finalidade de atender aos serviços oferecidos e
              sempre melhorar a experiência do usuário, não fazendo uso indevido
              de divulgação desses dados.
            </Text>
            <Text>
              Temos o cuidado de alertar que nenhum sistema de segurança é
              totalmente seguro, sendo assim a TAKE ME. ONLINE se desobriga de
              quaisquer responsabilidades por eventuais danos decorrentes de
              vírus ou invasões ao banco de dados do site.
            </Text>
            <Text>
              Nosso site pode ter links para sites externos que não são operados
              por nós. Esteja ciente de que não temos controle sobre o conteúdo
              e práticas desses sites e não podemos aceitar responsabilidade por
              suas respectivas políticas de privacidade.
            </Text>
            <Text>
              Você é livre para recusar a nossa solicitação de informações
              pessoais, entendendo que talvez não possamos fornecer alguns dos
              nossos serviços desejados. O uso continuado de nosso site será
              considerado como aceitação de nossas práticas em torno de
              privacidade e informações pessoais. Se você tiver alguma dúvida
              sobre como lidamos com dados do usuário e informações pessoais,
              entre em contato conosco.
            </Text>
            <Title>DADOS COLETADOS</Title>
            <Text>
              <b>I.</b> Para podermos operar todos os nossos serviços, serão
              coletados os seguintes dados:
            </Text>
            <Text>
              <b>•</b> Dados pessoais
            </Text>
            <Text>
              Nome; CPF; telefone; endereço; e-mail; data de nascimento;
              informações de meios de pagamento; lista de desejos (produtos
              selecionados pelo usuário dentro do login); histórico de compra e
              venda de produtos e serviços.
            </Text>
            <Text>
              <b>•</b> Dados de navegação
            </Text>
            <Text>
              IP, características do dispositivo; navegador; sistema
              operacional; data e hora; geolocalização; informações de conta
              (ex: login e senha); e cookies.
            </Text>
            <Text>
              <b>II.</b> A coleta desses dados ocorre nos seguintes momentos: Ao
              acessar nosso site; login e logout; ao realizar o cadastro; ao
              efetuar uma compra ou venda; e ao clicar em algum produto ou
              serviço.
            </Text>
            <Text>
              <b>III.</b> Os dados serão coletados com a seguinte finalidade:
              Identificar nossos clientes no sistema; segurança do site;
              responder dúvidas; comunicação; envio e coleta de produtos;
              efetuar pagamentos e cobranças; envio de informações de marketing;
              dentre outras formas para melhorar o atendimento de nossos
              serviços.
            </Text>
            <Title>COOKIES</Title>
            <Text>
              Nosso site faz o uso de cookies, comum em quase todos os sites,
              para melhorar a experiência do usuário; podendo com isso, coletar
              dados como os mencionados acima.
            </Text>
            <Text>
              Você pode impedir a configuração de cookies ajustando as
              configurações do seu navegador . Esteja ciente de que a
              desativação de cookies afetará a funcionalidade do site.
            </Text>
            <Title>MAIS INFORMAÇÕES</Title>
            <Text>
              As informações de Política de Privacidade poderão ser atualizadas
              e/ou modificadas a qualquer momento, sendo de responsabilidade dos
              usuários verificá-las sempre que acessar o site. Ao serem
              atualizadas será considerado a aceitação pelo usuário assim que
              acessar a plataforma.
            </Text>
            <Text>
              A TAKE ME elege o foro da Comarca de São Paulo, com a renuncia do
              usuário de qualquer outro foro, por mais privilegiado que seja
              para resolução de qualquer litigio ou conflito decorrente da
              presente Política de Privacidade, que será interpretada de acordo
              com a legislação brasileira.
            </Text>
            <Text>
              Esperemos ter esclarecido, quaisquer duvidas, entrar em contato
              com a Equipe TAKE ME através do WhatsApp: (11) 9 999-00100 ou através do
              endereço de e-mail contato@takeme.online.
            </Text>
            <Text>Esta política é efetiva a partir de Novembro/2023.</Text>
          </Section>
          {/* <Section id="section4">
            <Title>Entregas e Retiradas</Title>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
          </Section>
          <Section id="section5">
            <Title>Métodos de Pagamento</Title>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Nam quidem est autem optio minus quaerat,
              aliquam consequatur aut numquam ad molestias facere fuga quae quam
              perferendis. Dolores culpa laudantium soluta.
            </Text>
            <Text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              aliquid magnam ipsam saepe, labore veritatis, neque, ex similique
              dolor cupiditate illum quia minus modi voluptates tempore sint
              possimus laudantium fuga! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Numquam omnis laborum commodi incidunt adipisci
              tempore ullam? Ex harum illum ipsam, mollitia dolor totam odit,
              exercitationem aliquid eaque, earum porro aperiam! Lorem ipsum
              dolor, sit amet consectetur adipisicing elit. Quae velit optio ad.
              Non iste blanditiis sunt placeat soluta sint quam in quisquam
              molestiae, cum repudiandae aperiam reiciendis amet accusamus quis.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime,
              aliquam dolorum, architecto hic consequuntur, explicabo recusandae
              eaque ad suscipit dolores labore quidem accusantium possimus in
              numquam cumque! Dolorum, dignissimos et!
            </Text>
          </Section> */}
        </Content>
      </PageContainer>
      <Follow />
    </Layout>
  );
};

export default Termos;
