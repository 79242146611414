import styled from "styled-components";

export const Header = styled.div`
  border: 1px solid #000000;
  border-top: 0px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
  border-left: none;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const SectionCartao = styled.div`
  width: 80%;
  margin-top: 30px;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  label {
    font-size: 14px;
  }

  input {
    border-radius: 20px;
    border:1px solid #ccc;
    width: 90%;
    height: 40px;
    padding-left: 10px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  p,
  span {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      width: 90%;
      margin: 2% 4%;
      font-size: 16px;
    }

    label {
      margin: 0 4%;
    }
  }
`;
export const CartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Button = styled.button`
  width: 100%;
  display: flex;
  height: 35px;
  font-size: 14px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background-color: #000;
    color: #fff;
  }
  @media (max-width:1068px){
    margin:auto;
  }
`;

export const CartTable = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  height: 700px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
`;
export const Table = styled.table`
  width: 100%;
  margin-top: 2%;

  th:nth-child(1),
  td:nth-child(1) {
    width: 20%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 25%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 33%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 15%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 40%;
  }

  th {
    padding: 10px;
    text-align: start;
  }
`;

export const Td = styled.td`
  padding: 10px;

  img {
    height: 150px;
  }
`;

export const TableRow = styled.tr`
  position: relative;

  th {
    font-size: 14px;
    font-weight: 400;
  }

  td {
    font-size: 14px;
  }

`;

export const TableSeparatorUp = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #000;
`;

export const TableSeparatorDown = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000;
`;

export const TotalRow = styled.tr`
  position: relative;

  th {
    font-weight: normal;
    font-size: 14px;
    padding: 12px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 20%;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 6%;
  }
`;

export const ContinueButton = styled.button`
  width: 40%;
  padding: 30px 10%;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  background-color: #fff;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 20px 10%;
  }
`;

export const CheckoutButton = styled.button`
  width: 40%;
  padding: 30px 10%;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 20px 10%;
    margin-top: 5%;
  }
`;

export const RemoveItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const CartEmpty = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const BoxEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 3%;

  @media (max-width: 1024px) {
    margin-top: 7%;
  }

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`;

export const TitleEmpty = styled.p`
  font: normal normal normal 20px ${(props) => props.theme.fontTitle.family};
  border: 1px solid #989898;
  color: #989898;
  padding: 20px 180px;

  @media (max-width: 1024px) {
    font-size: 16px;
    padding: 20px 100px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 20px 100px;
  }
`;

export const ButtonQuantity = styled.button`
  background: none;
  border: none;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 0px 10px;

  div {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  span {
    padding-bottom: 5px;
    font-size: 14px;
  }
`;

export const DivTotal = styled.div`
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  border-bottom: 1px solid;
  font-weight: 400;
  font-size: 14px;
`;

export const RemoveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;
