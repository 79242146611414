import styled from "styled-components";
import backg_img from "../../assets/imgs/chapeu_vestido.jpg";

export const BackgroundImage = styled.div`
  width: 100%;
  height: 438px;
  background-image: url(${backg_img});
  background-size: cover;
  position: relative;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.span`

color: #000;
  font-family: "Thasadith", sans-serif;
  font-size: 40px;
  font-style: normal;
  line-height: normal;
  @media (max-width: 768px) {
  position: absolute;
  left: 60px;
  bottom: 62px;
    color: #000;
    font-family: "Thasadith", sans-serif;
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    left: 20px;
    top: 10px;
  }
`;

export const Text = styled.span`
  color: #000;
  font-family: Thasadith;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 0px;
  width: 463px;

  @media (max-width: 768px) {
    color: #000;
    font-family: Thasadith;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width:400px;
    position: relative;
    top: 30px;
    margin-right: 5px;
    margin-left: 5px;
  }

  @media (min-width: 768px) {
    right: 2%;
  }

`;

export const InfoPage = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom:30px;
  @media (min-width: 1300px) {
    margin-left: 1%;
  }
  @media (min-width: 1400px) {
    margin-left: 5%;
  }
  @media (min-width: 1500px) {
    margin-left: 9%;
  }
  @media (min-width: 1600px) {
    margin-left: 10%;
  }
  @media (min-width: 1700px) {
    margin-left: 12%;
  }
  @media (min-width: 1800px) {
    margin-left: 15%;
  }

  @media (max-width: 768px) {
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 0; 
  }
`;

export const AmebaImg = styled.img`
  margin-top: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BorderBottom = styled.div`
  border: 1px solid #000;
  width: 90%;
  color: black;
  top: 55px;
  position: absolute;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  flex-direction: column; 
  @media (max-width: 768px) {
    width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column; 
  }
`;
