import React, { useState } from "react";
import {
  ProductImageContainer,
} from "./styles";
import NoImage from "assets/imgs/nomedia.png";
import useImageProgressive from "hooks/useImageProgressive";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductImage = ({ image, hoveredImage, altImage, id, imageLow }) => {
  const [imageProduct] = useImageProgressive(
    imageLow,
    image
  );
  const [imageHovered] = useImageProgressive(
    imageLow,
    hoveredImage
  );

  const [hovered, setHovered] = useState(false);

  return (
    <>
      <ProductImageContainer
        onClick={() => window.open("/product/" + id, "_blank")}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <LazyLoadImage
          alt={altImage || NoImage}
          src={hovered ? imageHovered : imageProduct}
          effect="blur"
        />
      </ProductImageContainer>
    </>
  );
};

export default ProductImage;