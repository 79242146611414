import styled from "styled-components";

export const Header = styled.div`
  padding: 0 40px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
  position: relative;

  @media (max-width: 768px) {
    padding: 0 15px;
    display: none;
  }
`;


export const ProductImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
`;

export const ProductImage = styled.img`
  width: 100%;
  object-fit: cover;
  opacity: 1; 
  transition: opacity 0.3s ease;

  ${ProductImageContainer}:hover & {
    opacity: 0;
    transition: opacity 1.5s ease; 
  }
`;

export const HoveredImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${ProductImageContainer}:hover & {
    opacity: 1;
    transition: opacity 1.5s ease; 
  }
`;

export const ProductInfoOverlay = styled.div`
  position: absolute;
  top: ${(props) => (props.size === "small" ? "50%" : "60%")};
  left: 15%;
  width: 65%;
  background-color: white;
  border-radius: 50px;
  height: 40px;
  padding: 10px;
  z-index: 1;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s, transform 0.3s;

  @media (max-width: 768px) {
    position: absolute;
    top: ${(props) => (props.size === "one" ? "65%" : "40%")};
    left: 13%;
    width: 65%;
    background-color: white;
    border-radius: 50px;
    height: 30px;
    padding: 10px;
    z-index: 1;
    font-size: 11px;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s, transform 0.3s;
  

  }
`;

export const ProductInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const InfoText = styled.p`
  text-align: center;
  font-weight: 500;
  font-family: Thasadith;
  font-style: normal;
  line-height: normal;

`;

export const Button2 = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  margin-bottom: 10px;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background: none;
  transition: background-color 0.3s; 

  &:hover {
    background-color: #000;
    color: #fff; 
`;

export const InfoValue = styled.span`
  display: block;
`;
export const HeaderTitle = styled.div`
  color: #000;
  font-family: Thasadith;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  ${({ pointer }) => (pointer ? "cursor: pointer" : "")}
`;

export const Section = styled.div`
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &.filter-open {
    flex-basis: 100%;
  }
`;

export const ItemStore = styled.div`
  position: relative;
  width: ${(props) =>
    props.size === "small"
      ? "25%"
      : props.size === "large"
        ? "50%"
        : props.size === "one" ? "100%" : "33.3%"};
  }
  @media (max-width: 1000px) {
    height:auto;
    width: ${(props) =>
    props.size === "small"
      ? "25%"
      : props.size === "large"
        ? "50%"
        : props.size === "one" ? "100%" : "33.3%"};
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 2%;
  right: 5%;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 768px) {
    width: 18px;
  }
`;

export const ItemTop = styled.div`
  display: flex;
  justify-content: center;
  border-right: none;
  height: auto;
  overflow: hidden;
  padding: 5px;
  display:flex;
  margin-bottom:40px;
  flex-direction: column;
  img {
    width: 100%;
    transition: transform 2.3s;
    cursor: pointer;
  }

  &:hover ${ProductInfoOverlay}:before {
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 0.3s;
  }
  &:hover ${ProductInfoOverlay} {
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: 768px) {
   
`;


export const ProductBrand = styled.label`
  font-size: 18px;
  color: #000;
  font-family: Thasadith;
  font-style: normal;
  margin-top:10px;
  font-weight: 800;
  line-height: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 195px;
  @media (max-width: 768px) {
    font-size: 18px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 0;
    wordwrap: "break-word";
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    white-space: nowrap;
  }
`;

export const ProductName = styled.label`
  font-size: 14px;
  color: #000;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  wordwrap: "break-word";
  overflow: hidden;
  text-overflow: ellipsis;
  width: 195px;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 14px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 0;
    wordwrap: "break-word";
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    white-space: nowrap;
  }
`;

export const Category = styled.label`
  color: #c0b6ad;
  font-family: Thasadith;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
    color: #c0b6ad;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Price = styled.label`
  color: #000;
  font-family: Thasadith;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  align-self: flex-end;
  @media (max-width: 768px) {
    font-size: 14px;
    color: #000;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const FilterPanel = styled.div`
  display: flex;
  width: 20%;
  ${({ border }) =>
    border ? "border-top: 1px solid #000; border-right: 1px solid #000" : ""}
`;

export const HeaderMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    padding: 0 15px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
    position: relative;
    font-size: 14px;
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 10px 0;
`;

export const StyledSvg = styled.svg`
  width: 26px;
  height: 25px;
  margin-right: 10px;
  cursor: pointer;
  opacity: ${({ selected, size }) =>
    size === "normal" ? 1 : selected ? 1 : 0.5};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
  @media (max-width: 768px) {
    width: 26px;
    height: 25px;
    margin-right: 10px;
    cursor: pointer;
    opacity: ${({ selected, size }) =>
    size === "normal" ? 1 : selected ? 1 : 0.5};
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ContainerCardProduct = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  flex-direction: column; 
`;

export const TextPadrao = styled.span`
  font-size: 14px;
  color: #000;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
