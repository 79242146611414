import styled from "styled-components";

export const Header = styled.div`
  padding: 0 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ec;
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 150px;
  background-color: #f7f4ec;

  @media (max-width: 768px) {
    padding: 5px 20px;
  }
`;

export const Question = styled.h3`
  font-size: 30px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Answer = styled.p`
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid #000;
  margin: 10px 0;
`;
