import styled from "styled-components";

export const Content = styled.div`
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")};
`;

export const Image = styled.div`
  width: 50%;
  ${({ borderRight }) => (borderRight ? "border-right: 1px solid #000" : "")}

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media (min-width: 769px) {
    height: 100vh;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: rgb(245 215 173);
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 50vh;
  }
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
   background-position: center; 
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 768px) {
    width: 100vw;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 40px 0;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 60px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const Text = styled.p`
  text-align: center;
  width: 60%;
  margin: 0 auto;
  padding: 40px 0;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 20%;
  padding-top: 6%;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const NumberCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #ffc36f;
  font-size: 18px;
  margin-bottom: 10px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  font-size: 24px;
  margin: 20px 0;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Description = styled.p`
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ButtonDiv = styled.div`
  padding: 80px;

  @media (max-width: 768px) {
    padding: 30px;
    width:80%;
  }
`;

export const ButtonSt = styled.div`
 padding: 30px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px;
    display: flex;
  }
`;
