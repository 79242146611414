const theme = {
  font: {
    family: "Flama",
    base: "18px",
  },
  fontTitle: {
    family: "Argesta",
  },
  fontSubTitle: {
    family: "HelveticaNeue",
  },
  color: {
    primary: "#F7F4EC",
    white: "#ffffff",
    secondary: "#D8DFC1",
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export default theme;
