import React, { useEffect } from "react";
import Layout from "../Layouts/Layout";
import Follow from "../../components/Follow";
import Banner from "../../components/Banner";
import SectionSingleHome from "../../components/SectionSingleHome";
// import SectionSelection from "../../components/SectionSelection";
import {
  BackgroundImage,
  BackgroundImageContent,
  ContentAmeba,
  BackgroundImageShoes,
  ContentLeft,
  Image2,
  ImageContainer,
  ImageContent,
  SectionCookie,
  ShopNow,
  Text,
  Title,
  TitleDiv,
  TitleDivSell,
  TitleSell,
} from "./styles";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import CookieModal from "components/CookieModal";
import useImageProgressive from "hooks/useImageProgressive";

const HomeVintage = () => {
  const navigate = useNavigate();
  const [image1] = useImageProgressive("assets/imgs/downgrade/bolsa_new_low.jpg", "assets/imgs/bolsa_new_ok.jpg");
  const [image2] = useImageProgressive("assets/imgs/downgrade/costas_modelo.jpg", "assets/imgs/costas_modelo.jpg");
  const [image3] = useImageProgressive("assets/imgs/downgrade/sapato_gucci.jpg", "assets/imgs/sapato_gucci.jpg");
  const [image4] = useImageProgressive("assets/imgs/downgrade/sapato_meia.jpg", "assets/imgs/sapato_meia.jpg");
  const [image5] = useImageProgressive("assets/imgs/downgrade/vestido_pb.jpg", "assets/imgs/vestido_pb.png");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const shopNowButtons = document.querySelectorAll(".shopNowButton");
    shopNowButtons.forEach((button) => {
      button.addEventListener("click", scrollToTop);
    });

    return () => {
      shopNowButtons.forEach((button) => {
        button.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  return (
    <Layout>
      <Banner />
      <SectionCookie>
        <CookieModal />
      </SectionCookie>

      <Button>
        <Link
          to="https://wa.me/message/APZHBOCKQT5SE1"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
          as="div"
        >
          AGENDE UM HORÁRIO
        </Link>
      </Button>
      <ImageContainer>
        <Image2 borderRight>
          <BackgroundImage image={image1}>
            <TitleDiv>
              <Title>BOLSAS</Title>
              <ShopNow
                className="shopNowButton"
                onClick={() =>
                  navigate({
                    pathname: "/shop",
                    search: `?search=BOLSA&categorylist=true`,
                  })
                }
              >
                SHOP NOW
              </ShopNow>
            </TitleDiv>
          </BackgroundImage>
        </Image2>
        <ImageContent>
          <BackgroundImageContent
            image={image2}
          />
        </ImageContent>
      </ImageContainer>

      <SectionSingleHome />

      <ImageContainer>
        <ImageContent>
          <BackgroundImageContent image={image3} />
        </ImageContent>

        <Image2 borderRight>
          <BackgroundImageShoes image={image4}>
            <TitleDiv>
              <Title>SAPATOS</Title>
              <ShopNow
                className="shopNowButton"
                onClick={() =>
                  navigate({
                    pathname: "/shop",
                    search: `?search=SAPATOS&categorylist=true`,
                  })
                }
              >
                SHOP NOW
              </ShopNow>
            </TitleDiv>
          </BackgroundImageShoes>
        </Image2>
      </ImageContainer>

      {/* <SectionSelection
        title={"SELEÇÃO TAKE ME VINTAGE"}
        subTitle={"Ver tudo"}
        img1={require("assets/imgs/product1.png")}
      /> */}

      <ImageContainer border>
          <ContentLeft>
            <ContentAmeba>
              <img src={require("assets/imgs/wave.png")} style={{ width: "90%", height: "auto" }} alt="Ameba"/>
              <TitleSell
              >SELL WITH US</TitleSell>
            </ContentAmeba>
            <TitleDivSell>
              <Text>
                Agora você pode desapegar com a TAKE ME e direcionar todo o lucro das peças para instituições de caridade, da nossa seleção ou da sua escolha.
              </Text>
              <ShopNow>
                <Link className="shopNowButton" to="/sellwithus" style={{ color: "#000" }}>
                  VER TUDO
                </Link>
              </ShopNow>
            </TitleDivSell>
          </ContentLeft>
        <ImageContent>
          <BackgroundImageContent
            image={image5}
            style={{ backgroundPosition: "top" }}
          />
        </ImageContent>
      </ImageContainer>
      <Follow />
    </Layout>
  );
};

export default HomeVintage;
