import { useState, useEffect } from 'react';

function useImageProgressive(lowImage, highImage) {
  const [image, setImage] = useState(lowImage);


  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = highImage;
    imageToLoad.onload = () => {
      setImage(imageToLoad.src);
    }
  }, [highImage]);

  useEffect(() => {
    setImage(lowImage);
  }, [lowImage]);

  return [image];
}
export default useImageProgressive;