import styled from "styled-components";

export const Header = styled.div`
  border: 1px solid #000000;
  border-top: 0px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
  border-left: none;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const CartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
`;

export const CartTable = styled.div`
  width: 80%;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 5%;

  th:nth-child(1),
  td:nth-child(1) {
    width: 15%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 20%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 15%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 15%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 20%;
  }

  th {
    padding: 10px;
    text-align: start;
  }
`;

export const Td = styled.td`
  padding: 10px;

  img {
    height: 150px;
  }

  @media(max-width: 1068px){
    img {
      height: 150px;
    }
  }
`;

export const TableRow = styled.tr`
  position: relative;

  th {
    font-size: 14px;
    font-weight: 400;
  }

  td {
    font-size: 14px;
  }
`;

export const TableSeparatorUp = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #000;
`;

export const TableSeparatorDown = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000;
`;

export const TotalRow = styled.tr`
  position: relative;

  th {
    font-weight: normal;
    font-size: 14px;
    padding: 12px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8%;
  margin-bottom: 20%;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 6%;
  }
`;

export const ContinueButton = styled.button`
  width: 100%;
  padding: 14px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  background-color: #000;
  font-size: 14px;
  color : #fff;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    color : #fff;
    background-color: #000;
  }
`;


export const RemoveItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-size:18px;
`;

export const CartEmpty = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const BoxEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 3%;

  @media (max-width: 1024px) {
    margin-top: 7%;
  }

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`;


export const ButtonQuantity = styled.button`
  background: none;
  border: none;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height:100vh;
  position: absolute;
  z-index: 9999;
  @media (max-width: 1068px) {
    width: 305%;
    display: flex;
    height: 100%;
    overflow:auto;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 9999;
  }
`;

export const Content = styled.div`
  width:80%;
  justify-content:center;
  padding-top:20px;
  height:auto;
  @media(max-width: 1068px){
    width:100%;
    display:flex;
    justify-content:center;
  overflow:auto;
  }
`;

export const Text = styled.div`
  color: #000;
  font-family: Thasadith;
  font-size: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 0px 30px;
  width:100%;

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    padding-bottom: 5px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    padding: 0px 1%;

    div {
      width: 180px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  margin-top:10px;

  @media (max-width: 1068px) {
    width: 100%;
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid var(--preto-100, #000);
    transition: background-color 0.3s;

  &:hover {
    background-color: #000;
    color: #fff;
  }
  }
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin-right:30px;
`;



export const TitleEmpty = styled.p`
color: #000;
font-family: Thasadith;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal; 
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const DivTotal = styled.div`
  display: flex;
  padding-top:15px;
  padding-bottom:15px;
  justify-content: space-between;
  border-bottom: 1px solid ;
  font-weight: 400;
  font-size: 14px;
`;
export const ImgLogo = styled.div`
  display: flex;
  height: 200px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background: #f7f4ec;
  flex-shrink: 0;
  align-self: stretch;
`;
export const ModalContent = styled.div`
  flex-direction: column;
  align-items: left;
  background-color: #fff;
  width: 100%;
  z-index: 9999;
  
  @media (max-width: 1068px) {
    width: 100%;
    display: flex;
    height: auto;
    padding:40px;
  }
`;