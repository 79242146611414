import React from "react";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import CardProduct from "components/CardProduct";
import Banner from "components/BannerHeader";

const Sale = () => {
  return (
    <Layout colorMenu={"#fff"}>
      <Banner imgBanner="" title="Sale" description=""/>
      <CardProduct  filters="sale"/>
      <Follow />
    </Layout>
  );
};

export default Sale;
