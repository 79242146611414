export const shops = [
  // VESTUÁRIO
  {
    title: "VESTUÁRIO",
    items: [
      { text: "Alfaiataria" },
      { text: "Calças" },
      { text: "Camisas" },
      { text: "Casacos" },
      { text: "Jeans" },
      { text: "Malhas" },
      { text: "Saias" },
      { text: "Tops" },
      { text: "Vestidos" },
    ],
  },
  // SAPATOS & ACESSÓRIOS
  {
    title: "SAPATOS & ACESSÓRIOS",
    items: [
      { text: "Bolsas" },
      { text: "Cintos" },
      { text: "Chapéus" },
      { text: "Lenços" },
      { text: "Óculos" },
      { text: "Sapatos" },
    ],
  },
  // OCASIÃO
  {
    title: "OCASIÃO",
    items: [
      // { text: "Casamento" },
      { text: "Festa" },
      { text: "Neve" },
      { text: "Praia" },
    ],
  },
];
