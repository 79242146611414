import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: none;
  background-color: #f7f4ec;
  ${({ border }) => (border ? "border-bottom: 1px solid #000000" : "")}
  `;

export const SearchInput = styled.input`
  flex: 1;
  margin-top:10px;
  margin-bottom:10px;
  margin-left:20px;
  margin-right:20px;

  padding:10px;
  font-size: 13px;
  border: none;
  width:70%;
  background-color: #f7f4ec;

  ::placeholder {
    color: #000;
    font-size:16px;
  }
`;

export const SearchInputFilter = styled.input`
  flex: 1;
  margin-left:20px;
  margin-bottom:10px;
  padding:4px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius:30px;
  width:70%;
  background-color: #fff;

  ::placeholder {
    color: #000;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 40px;
  font-size: 20px;
`;
