import styled from "styled-components";

export const MenuDiv1 = styled.div`
  z-index: 10;
  position: relative;

  ${({ open }) => (!open ? "display: none" : "")}
`;

export const MenuDiv2 = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height:600px;

`;

export const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  gap: 16px;
  margin: 2% 2%;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.color.primary};
  color: #000;
  font-size: 20px;
  padding: 4px 8px;
  border: none;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : "normal")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 7px;
  margin-right: 20px;
`;

export const Span = styled.span`
  margin-bottom: 20px;
  font-size: 16px;
`;

export const Content = styled.div`
  display: flex;
  width: 77.3%;
`;

export const ListShop = styled.div`
  /* flex: 1; */
  background-color: #fff;
  width: 100%;
  border: 1px solid #000000;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: ${({ theme }) => theme.color.primary};
  padding-top: 50px;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
`;

export const ListBrands = styled.div`
  flex: 1;
  background-color: #fff;
  width: 100%;
  border: 1px solid #000000;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 3% 2% 2% 2%;
  display: flex;
  flex-direction: column;
`;

export const Background = styled.div`
  flex: 1;
  background-color: #fff;
  background-size: cover;
  border: 1px solid #000000;
  border-right: none;
  border-top: none;

`;

export const Imagem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemList = styled.div`
  font: normal normal normal 20px ${(props) => props.theme.font.family};
  letter-spacing: 0px;
  margin-bottom: 8px;
  font-weight: ${({ isBold }) => (isBold ? isBold : 300)};
  cursor: pointer;
`;
