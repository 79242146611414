import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaSquareFull } from "react-icons/fa";
import axios from "axios";
import {
  Content,
  ItemStore,
  ItemTop,
  Price,
  ProductName,
  Section,
  LineSeparator,
  ProductInfoOverlay,
  ProductInfoItem,
  ProductBrand,
  InfoText,
  InfoValue,
  Button2,
  StyledSvg,
  ContainerCardProduct,
  TextPadrao,
} from "./styles";
import { useSearchParams } from "react-router-dom";
import configs from "config";
import Filter from "components/Filter";
import Loader2 from "components/Loader2";
import ProductImage from "components/ProductImage";

const CardProduct = ({ filters }) => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 24;
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [page, setPage] = useState(1);
  const [imageCache] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [itemSize, setItemSize] = useState("large");
  const [selectedSize, setSelectedSize] = useState("large");
  const [apiCalled, setApiCalled] = useState(false);
  const [filtroOn, setFiltroOn] = useState(false);
  const [total, setTotal] = useState("");
  const [loading2, setIsLoading2] = useState(false);
  const [search, setSearch] = useState("");

  const [typeFilter, setTypeFilter] = useState();
  useEffect(() => {
    const queryString = searchParams.get("search");

    setSearch(queryString || "");
    setTypeFilter(filters);

  }, [searchParams, setTypeFilter, filters]);

  function removeAccentsAndModify(text) {
    let modifiedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    modifiedText = modifiedText.replace(/'/g, "");
    if (modifiedText === "ALA%C3%8FA") {
      modifiedText = "ALAIA";
    }
    if (modifiedText === "SEE BY CHLOE") {
      modifiedText = "SEE BY CHLOÉ";
    }
    if (modifiedText === "CHLOE") {
      modifiedText = "CHLOÉ";
    }

    if (modifiedText === "REINALDO LOURENCO") {
      modifiedText = "REINALDO LOURENÇO";
    }

    if (modifiedText === "HERMES") {
      modifiedText = "HERMÈS";
    }

    return modifiedText;
  }

  function removeAccetsAndSpace(text) {
    let modifiedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '');
    if (modifiedText === "CALCA") {
      modifiedText = "CALÇA";
      return modifiedText
    }
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '');
  }
  const preloadImages = useCallback(
    (imagePaths) => {
      imagePaths.forEach((path) => {
        if (!imageCache[path]) {
          const img = new Image();
          img.src = path;
          imageCache[path] = img;
        }
      });
    },
    [imageCache]
  );
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };


  const [filtros, setFiltros] = useState({
    categoria: [],
    tamanho: [],
    cor: [],
    ocasiao: [],
    marca: [],
    preco: { min: null, max: null },
    orderBy: [],
  });

  const handleFilterChange = useCallback(
    async (filtersProduct) => {
      const searchValue = searchParams.get("search");
      let url = "";
      setIsLoading2(true);
      const compareFilters = () => {
        for (const key in filtros) {
          if (JSON.stringify(filtros[key]) !== JSON.stringify(filtersProduct[key])) {
            setPage(2);
            return true;
          }
        }
        return false;
      };
      compareFilters();
      try {
        if (searchValue !== search && (searchValue !== "shop" || search !== "shop")) {
          url = `${configs.api}get-allproducts?limit=${itemsPerPage}&page=1&takeme=true`;
          setAllProducts([]);
        } else if (compareFilters()) {
          url = `${configs.api}get-allproducts?limit=${itemsPerPage}&page=1&takeme=true`;
        } else {
          url = `${configs.api}get-allproducts?limit=${itemsPerPage}&page=${page}&takeme=true`;
          setIsLoading2(true)
        }
        if (
          filtersProduct.preco.min !== null &&
          filtersProduct.preco.max !== null
        ) {
          const precoString = JSON.stringify(filtersProduct.preco);
          const precoParam = encodeURIComponent(precoString);
          url += `&search=${typeFilter}&preco=${precoParam}`;
        }
        if (filtersProduct.orderBy) {
          url += `&search=${typeFilter}&sortDirection=${filtersProduct.orderBy}`;
        }
        if (filters !== "" && filters !== undefined) {
          url += `&search=${filters}`;
        }
        const filtroTemItens = (filtro) => filtro && filtro.length > 0;
        const inserirSearchValue = (campo) => {
          const searchValueLower = searchValue?.toLowerCase();

          if (
            filtroTemItens(filtersProduct[campo]) &&
            filtersProduct[campo].some((value) => value.toLowerCase() === searchValueLower)
          ) {
            filtersProduct[campo].push(searchValue);
          }
        };

        inserirSearchValue("categoria");
        inserirSearchValue("tamanho");
        inserirSearchValue("cor");
        inserirSearchValue("ocasiao");
        inserirSearchValue("marca");

        if (filtroTemItens(filtersProduct.categoria)) {
          setFiltros(filtersProduct.categoria);

          const categoriasSemAcentos = filtersProduct.categoria.map(removeAccetsAndSpace);
          url += `&search=${typeFilter}&group[]=${categoriasSemAcentos.map(encodeURIComponent).join("&group[]=")}`;
        }

        if (filtroTemItens(filtersProduct.tamanho)) {
          setFiltros(filtersProduct.tamanho);

          url += `&search=${typeFilter}&size[]=${filtersProduct.tamanho.map(encodeURIComponent).join("&size[]=")}`;
        }

        if (filtroTemItens(filtersProduct.cor)) {
          setFiltros(filtersProduct.cor);
          url += `&search=${typeFilter}&color[]=${filtersProduct.cor.map(encodeURIComponent).join("&color[]=")}`;
        }

        if (filtroTemItens(filtersProduct.ocasiao)) {
          setFiltros(filtersProduct.ocasiao);
          url += `&search=${typeFilter}&ocasiao[]=${filtersProduct.ocasiao.map(encodeURIComponent).join("&ocasiao[]=")}`;
        }

        if (filtroTemItens(filtersProduct.marca)) {
          setFiltros(filtersProduct.marca);
          const marcasSemAcentos = filtersProduct.marca.map(removeAccentsAndModify);
          url += `&search=${typeFilter}&brand[]=${marcasSemAcentos.map(encodeURIComponent).join("&brand[]=")}`;
        }

        const response = await axios.get(url);
        const newProducts = response.data.data;
        setAllProducts(newProducts);
        setFiltroOn(true);
        setHasMoreProducts(newProducts.length - response.data.total);
        setFiltros(filtersProduct);
        preloadImages(newProducts.map((product) => product.images[1]?.path));

      } catch (error) {
        console.error("Error fetching filtered products:", error);
      } finally {
        setIsLoading2(false);
      }
    },

    [
      setIsLoading2,
      setAllProducts,
      setFiltroOn,
      setHasMoreProducts,
      preloadImages,
      searchParams,
      typeFilter,
      page,
      search,
      filtros,
      filters

    ]
  );


  const formatPrice = (price) => {
    return price.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };


  const handleSizeButtonClick = (size) => {
    setItemSize(size);
    setSelectedSize(size);
  };
  const fetchProducts = useCallback(async () => {
    try {
      let url = `${configs.api}get-allproducts?limit=${itemsPerPage}&takeme=true`;
      if (filters !== "" && filters !== undefined) {
        url += `&search=${filters}`;
      }
      const busca = searchParams.get("busca");
      const brandlist = searchParams.get("brandlist");
      const categorylist = searchParams.get("categorylist");
      const filtroTemItens = (filtro) => filtro && filtro.length > 0;
      if ((searchParams.get("search") !== search) && searchParams.get("search")) {
        url += `&page=1`;
        setAllProducts([]);
        setPage(1);

        setIsLoading2(true)
      } else {
        url += `&page=${page}`;
        setIsLoading2(true)
      }
      if (brandlist === "true" || brandlist === true) {
        url += `&brand[]=${removeAccentsAndModify(encodeURIComponent(searchParams.get("search")))}`;

      } else if (categorylist === "true" || categorylist === true) {
        url += `&group[]=${removeAccentsAndModify(searchParams.get("search"))}`;
      }
      else {
        if (searchParams.get("search") && searchParams.get("search") !== "shop") {
          if (searchParams.get("search") === "Alaïa") {
            url += `&search=ALAIA`;
          } else {
            url += `&search=${searchParams.get("search")}`;
          }
        }
      }

      if (busca !== "true" || busca !== true) {
        if (filtroTemItens(filtros.categoria)) {
          setFiltros(filtros.categoria);
          url += `&group[]=${filtros.categoria.map(encodeURIComponent).join("&group[]=")}`;
        }

        if (filtroTemItens(filtros.tamanho)) {
          setFiltros(filtros.tamanho);
          url += `&size[]=${filtros.tamanho.map(encodeURIComponent).join("&size[]=")}`;
        }

        if (filtroTemItens(filtros.cor)) {
          setFiltros(filtros.cor);
          url += `&color[]=${filtros.cor.map(encodeURIComponent).join("&color[]=")}`;
        }

        if (filtroTemItens(filtros.ocasiao)) {
          setFiltros(filtros.ocasiao);
          url += `&ocasiao[]=${filtros.ocasiao.map(encodeURIComponent).join("&ocasiao[]=")}`;
        }

        if (filtroTemItens(filtros.marca)) {
          setFiltros(filtros.marca);
          const marcasSemAcentos = filtros.marca.map(removeAccentsAndModify);
          url += `&brand[]=${filtros.marca.map(marcasSemAcentos).join("&brand[]=")}`;
        }

        if (
          filtros.preco.min !== null &&
          filtros.preco.max !== null
        ) {
          const precoString = JSON.stringify(filtros.preco);
          const precoParam = encodeURIComponent(precoString);
          if (filtroTemItens(filtros.preco)) {
            url += `&preco=${precoParam}`;
          }
        }
        if (filtroTemItens(filtros.orderBy)) {
          setFiltros(filtros.orderBy);
          url += `&sortDirection=${filtros.orderBy}`;
        }

        setFiltros(filtros);
      }

      const response = await axios.get(url);
      const newProducts = response.data.data;


      setAllProducts((prevAllProducts) => {
        const existingProductIds = new Set(
          prevAllProducts.map((product) => product.id)
        );

        const uniqueNewProducts = newProducts.filter(
          (newProduct) => !existingProductIds.has(newProduct.id)
        );

        preloadImages(
          uniqueNewProducts.map((product) => product.images[1]?.path)
        );

        return [...prevAllProducts, ...uniqueNewProducts];
      });
      setPage((prevPage) => prevPage + 1);
      if (filters === "novidades") {
        setTotal(100);
        setHasMoreProducts(
          allProducts.length + newProducts.length < 100
        );
      } else {
        setTotal(response.data.total);
        setHasMoreProducts(
          allProducts.length + newProducts.length < response.data.total
        );

      }


      setApiCalled(true);
      setIsLoading2(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
    }
  }, [
    searchParams,
    allProducts,
    setIsLoading,
    setPage,
    preloadImages,
    page,
    search,
    filters,
    filtros,

  ]);

  const fetchProductsRef = useRef(fetchProducts);
  useEffect(() => {
    fetchProductsRef.current();

  }, [fetchProductsRef]);

  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    let newSize = "";
    const handleResize = () => {
      if (!isMobile) {
        const screenSize = window.innerWidth;
        newSize = screenSize < 1068 ? "large" : "normal";
        setItemSize(newSize);
        setSelectedSize(newSize);
      }

    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);
  const sortedProducts = allProducts;
  const handleMouseEnter = (productId) => {
    setHoveredProductId(productId);
    const product = allProducts.find((p) => p.id === productId);
    if (product && product.images.length > 1) {
      const img = new Image();
      img.src = product.images[1].path;
    }
  };



  const lastSearchParamsValue = useRef(searchParams.get("search"));
  useEffect(() => {
    const searchValue = searchParams.get("search");
    if (searchValue !== lastSearchParamsValue.current) {
      lastSearchParamsValue.current = searchValue;
      fetchProducts();
    }

  }, [searchParams, fetchProducts]);

  const handleMouseLeave = () => {
    setHoveredProductId(null);
  };

  const hasDuplicateKeys =
    new Set(allProducts.map((product) => product.id)).size !==
    allProducts.length;

  if (hasDuplicateKeys) {
    console.error("Existem chaves duplicadas na lista de produtos!");
  }

  const observerRef = useRef();


  return (
    <>
      <ContainerCardProduct>
        <Filter onFilterChange={handleFilterChange} searchFilter={search} />
        {!isMobile ? (
          <div style={{ padding: "20px", textAlign: "center" }}>

            <StyledSvg
              onClick={() => handleSizeButtonClick("large")}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              selected={selectedSize === "large"}
            >
              <g clip-path="url(#clip0_20_1638)">
                <path
                  d="M1 0H11.9375V10.9375H1V0ZM15.0625 0V10.9375H26V0H15.0625ZM1 25H11.9375V14.0625H1V25ZM15.0625 25H26V14.0625H15.0625V25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_1638">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(0.538086)"
                  />
                </clipPath>
              </defs>
            </StyledSvg>
            <StyledSvg
              onClick={() => handleSizeButtonClick("normal")}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              selected={selectedSize === "normal"}
            >
              <g clip-path="url(#clip0_20_1637)">
                <path
                  d="M0.538086 0H7.89103V7.35294H0.538086V0ZM9.36162 7.35294H16.7146V0H9.36162V7.35294ZM18.1851 0V7.35294H25.5381V0H18.1851ZM0.538086 16.1765H7.89103V8.82353H0.538086V16.1765ZM9.36162 16.1765H16.7146V8.82353H9.36162V16.1765ZM18.1851 16.1765H25.5381V8.82353H18.1851V16.1765ZM0.538086 25H7.89103V17.6471H0.538086V25ZM9.36162 25H16.7146V17.6471H9.36162V25ZM18.1851 25H25.5381V17.6471H18.1851V25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_1637">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(0.538086)"
                  />
                </clipPath>
              </defs>
            </StyledSvg>
            <StyledSvg
              onClick={() => handleSizeButtonClick("small")}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              selected={selectedSize === "small"}
            >
              <g clip-path="url(#clip0_20_1636)">
                <path
                  d="M1.00024 0H6.00024V5H1.00024V0ZM7.66691 5H12.6669V0H7.66691V5ZM14.3336 5H19.3336V0H14.3336V5ZM21.0002 0V5H26.0002V0H21.0002ZM1.00024 11.6667H6.00024V6.66667H1.00024V11.6667ZM7.66691 11.6667H12.6669V6.66667H7.66691V11.6667ZM14.3336 11.6667H19.3336V6.66667H14.3336V11.6667ZM21.0002 11.6667H26.0002V6.66667H21.0002V11.6667ZM1.00024 18.3333H6.00024V13.3333H1.00024V18.3333ZM7.66691 18.3333H12.6669V13.3333H7.66691V18.3333ZM14.3336 18.3333H19.3336V13.3333H14.3336V18.3333ZM21.0002 18.3333H26.0002V13.3333H21.0002V18.3333ZM1.00024 25H6.00024V20H1.00024V25ZM7.66691 25H12.6669V20H7.66691V25ZM14.3336 25H19.3336V20H14.3336V25ZM21.0002 25H26.0002V20H21.0002V25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_1636">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(0.538086)"
                  />
                </clipPath>
              </defs>
            </StyledSvg>
          </div>
        ) : (
          <div style={{ padding: "20px", textAlign: "center" }}>
            <StyledSvg onClick={() => handleSizeButtonClick("one")} selected={selectedSize === "one"} ><FaSquareFull style={{ fontSize: "25px", marginLeft: "10px" }}></FaSquareFull></StyledSvg>
            <StyledSvg
              onClick={() => handleSizeButtonClick("large")}
              viewBox="0 0 26 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              selected={selectedSize === "large"}
            >
              <g clip-path="url(#clip0_20_1638)">
                <path
                  d="M1 0H11.9375V10.9375H1V0ZM15.0625 0V10.9375H26V0H15.0625ZM1 25H11.9375V14.0625H1V25ZM15.0625 25H26V14.0625H15.0625V25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_1638">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(0.538086)"
                  />
                </clipPath>
              </defs>
            </StyledSvg>
          </div>
        )}
        <Section>
          <Content>
            {isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Loader2 />
              </div>
            )}
            {loading2 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Loader2 />
              </div>
            )}
            {apiCalled && sortedProducts.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {!loading2 && sortedProducts.length && (
                  <TextPadrao>
                    Nenhum produto encontrado.
                  </TextPadrao>
                )}
              </div>
            ) : (
              sortedProducts.map((product) => {
                const mainPhotoIndex = product.images.findIndex(image => image.foto_principal);
                return (
                  <ItemStore
                    key={product.id}
                    size={itemSize}
                    onMouseEnter={() => handleMouseEnter(product.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <ItemTop size={itemSize}>
                      {hoveredProductId === product.id && (
                        <ProductInfoOverlay size={itemSize}>
                          {product.group !== "BOLSA" &&
                            product.group !== "MALA" && (
                              <ProductInfoItem>
                                <InfoText>Tamanho</InfoText>
                                <InfoValue>{product.size}</InfoValue>
                              </ProductInfoItem>
                            )}
                          <ProductInfoItem>
                            <InfoText>Estado</InfoText>
                            <InfoValue>{product.conditionPart}</InfoValue>
                          </ProductInfoItem>
                        </ProductInfoOverlay>
                      )}
                      <ProductImage
                        image={mainPhotoIndex !== -1 ? product.images[mainPhotoIndex].path : product.images[0]?.path}
                        hoveredImage={
                          product.images.length > 1
                            ? mainPhotoIndex === 0
                              ? product.images[1]?.path // Se a foto principal for a 0, exibe a 1
                              : mainPhotoIndex !== 0
                                ? product.images[0]?.path // Se a foto principal for a 1, exibe a 0
                                : product.images[0]?.path
                            : product.images[0]?.path
                        }
                        altImage={product.alt}
                        id={product.id}
                        imageLow={mainPhotoIndex !== -1 && product.images[mainPhotoIndex].path_low && product.images[mainPhotoIndex].path_low !== ""
                          ? product.images[mainPhotoIndex].path_low
                          : "assets/imgs/backgroundProducts.jpg"}
                      />
                      <ProductBrand>
                        {product.brand?.replace(/-/g, "")}
                      </ProductBrand>
                      <ProductName>
                        {product.name?.replace(/-/g, "")}
                      </ProductName>
                      <LineSeparator />
                      <Price>
                        R$ {formatPrice(parseFloat(product.salePrice))}
                      </Price>
                    </ItemTop>
                   
                  </ItemStore>
                );
              })
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "3%",
              }}
            ></div>
            {!filtroOn && page !== 2 && (
              <div
                id="infinite-scroll-target"
                ref={observerRef}
                style={{ height: "10px" }}
              ></div>
            )}
            {hasMoreProducts && !isLoading && !loading2 && total > 24 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button2 onClick={fetchProducts}>Carregar mais...</Button2>
              </div>
            ) : !isLoading && !loading2 && (
              <Button2 onClick={handleScrollToTop}>ir para o topo...</Button2>
            )}

          </Content>
          {loading2 && sortedProducts.length >= 24 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Loader2 />
              </div>
            </>

          )}
        </Section>
      </ContainerCardProduct>
    </>
  );
};

export default CardProduct;
