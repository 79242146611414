import styled from "styled-components";

export const Content2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  .loader {
    overflow: visible;
    height: fit-content;
    width: fit-content;
    padding: 20px;
    display: flex;
  }

  .logo {
    fill: none;
    stroke-dasharray: 300px;
    stroke: black;
    animation: load 3s infinite ease-in-out;
  }

  @keyframes load {
    0% {
      stroke-dashoffset: 300px;
    }

    50% {
      stroke-dashoffset: 0px;
    }
    100% {
      stroke-dashoffset: 300px;
    }
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5); 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998; /* Um valor inferior ao z-index do modal */
`;

