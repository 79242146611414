import React, { useState, useCallback } from "react";
import {
  CloseButton,
  ModalContainer,
  ModalContent,
  menuItem,
  scrollContainer,
  fontMenuDropDown,
  ItemList,
  ListBrands,
  ListShop,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { brandsMobile } from "../../containers/Layouts/brandsMobile";
import { shops } from "../../containers/Layouts/shops";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const ModalMobile = ({ onClose, isOpen }) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeShopSection, setActiveShopSection] = useState(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const removeSFromEnd = (text) => {
    if (text.endsWith("s")) {
      return text.slice(0, -1);
    }
    return text;
  };
  
  const handleClick = useCallback(
    (item) => {
      handleClose();
      if (item === "all") {
        navigate({ pathname: "/brandlist" });
        return;
      }else if (item !== "Ver Tudo"){
        navigate({
          pathname: "/shop",
          search: `?search=${removeSFromEnd(item.text)}`,
        });
      }else{
        navigate({
          pathname: "/shop",
        });
      }
    },
    [navigate, handleClose]
  );


  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const toggleShopSection = (index) => {
    setActiveShopSection(activeShopSection === index ? null : index);
  };

  const getSectionItems = (items) => {
    return items.map((item, index) => (
      <ItemList style={menuItem} key={index} onClick={() => handleClick(item)}>
        <span style={fontMenuDropDown}>{item.text}</span>
      </ItemList>
    ));
  };

  return (
    <>
      {isOpen && (
        <ModalContainer>
          <ModalContent>
            <CloseButton onClick={handleClose}>X</CloseButton>
            <section>
              <div onClick={() => navigate("/novidades")} style={menuItem}>
                <span>New in</span>
              </div>

              <div onClick={() => toggleMenu("shop")} style={menuItem}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <span>Shop</span>
                  {activeMenu === "shop" ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>
              </div>
              {activeMenu === "shop" && (
                <div style={scrollContainer}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <span onClick={() => handleClick("Ver Tudo")} style={{ ...menuItem, fontSize: "14px", paddingLeft: "20px", backgroundColor: "rgb(234, 229, 211)", }}>VER TUDO</span >
                  </div>
                  {shops.map((section, index) => (
                    <div key={index}>
                      <div
                        onClick={() => toggleShopSection(index)}
                        style={{ ...menuItem, paddingLeft: "20px" }}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <span style={fontMenuDropDown}>{section.title}</span >
                          {activeShopSection === index ? (
                            <MdKeyboardArrowUp />
                          ) : (
                            <MdKeyboardArrowDown />
                          )}

                        </div>

                      </div>

                      {activeShopSection === index && (
                        <ListShop style={{ marginBottom: "20px" }}>
                          {getSectionItems(section.items)}
                        </ListShop>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <div onClick={() => toggleMenu("brand")} style={menuItem}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <span>Marcas</span>
                  {activeMenu === "brand" ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>
              </div>
              {activeMenu === "brand" && (
                <div style={scrollContainer}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <span onClick={() => handleClick("all")} style={{ ...menuItem, fontSize: "14px", paddingLeft: "20px", backgroundColor: "rgb(234, 229, 211)", }}>VER TUDO</span >
                  </div>

                  {brandsMobile.map((section, index) => (
                    <ListBrands key={index} style={{ marginBottom: "20px" }}>
                      {getSectionItems(section.items)}
                    </ListBrands>
                  ))}
                </div>
              )}
            </section>

            <section>
              <div onClick={() => navigate("/sellwithus")} style={menuItem}>
                <span>Sell With Us</span>
              </div>
              <div onClick={() => navigate("/giveme")} style={menuItem}>
                <span>Give Me</span>
              </div>
            </section>

            <section>
              <div style={menuItem}>
                <span>
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href="https://www.instagram.com/takeme.online/"
                  >
                    Instagram
                  </a>
                </span>
              </div>
              <div style={menuItem}>
                <span>
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href="/about"
                  >
                    About
                  </a>
                </span>
              </div>
              <div style={menuItem}>
                <span>
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href="/termos#section3"
                  >
                    Política de Privacidade
                  </a>
                </span>
              </div>
              <div style={menuItem}>
                <span>
                  <a
                    style={{ textDecoration: "none", color: "#000" }}
                    href="/faq"
                  >
                    FAQ
                  </a>
                </span>
              </div>
            </section>
          </ModalContent>
        </ModalContainer>
      )}
    </>
  );
};

export default ModalMobile;
