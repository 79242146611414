import { Button, ButtonSpan } from "./styles";

const ButtonMenu = ({ children, color, active, border }) => {
  return (
    <Button active={active} color={color} border={border}>
      {children}
      <ButtonSpan />
    </Button>
  );
};

export default ButtonMenu;
