import {
  ContainerText,
  Header,
  HeaderTitle,
  Text,
  CardContainer,
  Card,
  Title,
  Description,
  NumberCircle,
  ButtonDiv,
  ImageContainer,
  Image,
  BackgroundImage,
  TextDiv,
  ButtonSt,
  Content,
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import Button from "components/Button";
import { Link } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";

const GiveMe = () => {
  const [image1] = useImageProgressive("assets/imgs/downgrade/model_orange.jpg"    , "assets/imgs/model_orange.jpg");

  return (
    <Layout>
      <Content border>
        <ImageContainer border>
          <Image borderRight>
            <BackgroundImage
              image={image1}
            />
          </Image>
          <TextDiv>
            <Header>
              <HeaderTitle>GIVE ME</HeaderTitle>
            </Header>
            <Text>
              Agora você pode desapegar com a TAKE ME
              <br /> e direcionar todo o valor das peças para instituições de
              caridade, da nossa seleção ou da sua escolha.
            </Text>
            <Button>
              <Link
                to="https://wa.me/message/APZHBOCKQT5SE1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
                as="div" // Renderiza o Link como uma div
              >
                AGENDE UM HORÁRIO
              </Link>
            </Button>
          </TextDiv>
        </ImageContainer>
        <ContainerText>
          <Header>
            <HeaderTitle>
              CONHEÇA
              <br /> A GIVE ME
            </HeaderTitle>
          </Header>
          <Text>
            Um novo jeito de desapegar das suas peças
            <br /> e dar novos sentidos para elas junto com a TAKE ME.
          </Text>
          <CardContainer>
            <Card>
              <NumberCircle>01</NumberCircle>
              <Title>
                Novos caminhos
                <br />
                para suas peças
              </Title>
              <Description>
                A gente cuida de tudo para você. Separe as roupas que você não
                usa mais - o primeiro passo para doar suas roupas é separar aquelas que você não usa mais ou que não servem mais em você.
                Certifique-se de que as peças estejam em boas condições e
                limpas.
              </Description>
            </Card>
            <Card>
              <NumberCircle>02</NumberCircle>
              <Title>
                Desapego +<br />
                Curadoria Take Me
              </Title>
              <Description>
                Um novo propósito para aqueles looks que não fazem mais sentido
                para você. Entre em contato com a equipe Take Me para agendar a
                entrega das peças. Nós iremos informar as condições para a
                doação.
              </Description>
            </Card>
            <Card>
              <NumberCircle>03</NumberCircle>
              <Title>
                Deixa com
                <br />a gente
              </Title>
              <Description>
                Entregue as roupas - uma vez que você tenha agendado a entrega.
                A equipe irá avaliar as peças e aceitar as que estiverem em boas
                condições e adequadas para o acervo da loja.
              </Description>
            </Card>
          </CardContainer>

          <Text style={{ marginTop: "8%" }}>
            As peças selecionadas pelo formato de doação Give Me levam um selo
            no site do CJ Fashion em que todo dinheiro arrecadado é direcionado
            para instituições de caridade, de forma rápida e segura - por meio
            de PIX.
          </Text>

          <ButtonDiv>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div" // Renderiza o Link como uma div
            >
              <ButtonSt>QUERO VENDER COM A TAKE ME</ButtonSt>
            </Link>
          </ButtonDiv>
        </ContainerText>
      </Content>
      <Follow />
    </Layout>
  );
};

export default GiveMe;
