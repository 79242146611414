import Button from "components/Button";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import {
  Answer,
  FAQContainer,
  Header,
  HeaderTitle,
  Question,
  Separator,
} from "./styles";
import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <Layout>
      <Header border>
        <HeaderTitle>FAQ</HeaderTitle>
      </Header>

      <FAQContainer border>
        <Button>
          <Link
            to="https://wa.me/message/APZHBOCKQT5SE1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#000" }}
            as="div" // Renderiza o Link como uma div
          >
            AGENDE UM HORÁRIO
          </Link>
        </Button>
        <div style={{ paddingTop: "0" }}>
          <Question>O que a Take Me tem em sua curadoria?</Question>
          <Answer>
            Do vestido para a festa de gala ao jeans que vai te acompanhar no cotidiano: cada um dos XXX itens no acervo da Take Me foi escolhido a dedo. Mais do que a peça do momento, nossa curadoria se baseia em uma seleção de peças que atravessam o tempo com estilo, relevância e qualidade.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Qual é a diferença entre second-hand e vintage?</Question>
          <Answer>
            Chamamos de vintage uma peça que foi feita um bom tempo atrás, mas segue mantendo sua importância estética e histórica. Peças second-hand pertencem a coleções recentes e, quando bem feitas, não perdem o fator desejo apenas pelo fato de que foram lançadas em estações passadas.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Qual a condição das peças vendidas?</Question>
          <Answer>
            Antes de entrar na curadoria Take Me, as peças são avaliadas uma a uma, com um critério rigoroso de controle de qualidade. Se necessário, fazemos pequenos reparos (que não descaracterizem o design original da peça) antes de oferecê-la a você. Fazemos também a autenticação de bolsas utilizando serviço de inteligência artificial. Em nosso acervo, no entanto, você vai encontrar peças nunca antes usadas, no mais perfeito estado em que foram compradas inicialmente.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Preciso de um look para uma ocasião especial. A Take Me ajuda?</Question>
          <Answer>
            Claro. Nossas personal shoppers estão à sua disposição, online e presencialmente, para atender necessidades específicas, da festa de casamento às peças que vão na mala para uma viagem especial. Com a Take Me, você pode garantir o look completo, incluindo complementos que fazem a diferença, como bolsas, sapatos, cintos e acessórios. Tudo isso disponível para venda ou aluguel. Através do serviço Selection, também é possível usar a expertise de nossas fundadoras para encontrar algo de maneira absolutamente personalizada, mesmo que o look perfeito não esteja no acervo da Take Me.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Como posso comprar?</Question>
          <Answer>
            Contamos com 5 diferentes canais de atendimento:
            <ul>
              <li>Online, com o catálogo de compra e aluguel em nosso site <a href="/shop" style={{ textDecoration: "none", color: "black", fontSize: "12px" }} target="_blank" rel="noreferrer"> (clique aqui para acessar)</a></li>
              <li>Pelo Whatsapp, com auxílio de nossas personal shoppers <a href="https://wa.me/message/APZHBOCKQT5SE1" style={{ textDecoration: "none", color: "black", fontSize: "12px" }} target="_blank" rel="noreferrer"> (clique aqui para iniciar uma conversa)</a></li>
              <li>No acervo, que está localizado dentro do shopping Cidade Jardim, em São Paulo, onde atendemos com hora marcada <a href="https://wa.me/message/APZHBOCKQT5SE1" style={{ textDecoration: "none", color: "black", fontSize: "12px" }} target="_blank" rel="noreferrer">(clique aqui para agendar)</a></li>
              <li>No Shops Jardins, em São Paulo, onde temos uma loja física pop-up <a href="https://www.google.com/maps/dir//Rua+Haddock+Lobo,+1626+-+Jardins,+S%C3%A3o+Paulo+-+SP,+01414-002/@-23.5646099,-46.7514896,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94ce59d9c7638d7f:0x49b11c50db8a6d5a!2m2!1d-46.669088!2d-23.5646316?entry=ttu" style={{ textDecoration: "none", color: "black", fontSize: "12px" }} target="_blank" rel="noreferrer"> (clique aqui para o endereço completo)</a></li>
              <li>Na CJ Mares, também no shopping Cidade Jardim, onde mantemos uma seleção especial de peças, sempre atualizada com novidades em sinergia com a multimarcas <a href="https://www.google.com/maps/dir//Av.+Magalh%C3%A3es+de+Castro,+12.000+-+Jardim+Panorama,+S%C3%A3o+Paulo+-+SP,+05676-120/@-23.5987637,-46.7388196,13z/data=!3m1!5s0x94ce59d5dbc39d67:0x728fd05b208cf2be!4m8!4m7!1m0!1m5!1m1!1s0x94ce57307be2503b:0x12bb82312323efb0!2m2!1d-46.69762!2d-23.5988478?entry=ttu" style={{ textDecoration: "none", color: "black", fontSize: "12px" }} target="_blank" rel="noreferrer">(clique aqui para o endereço completo)</a></li>
            </ul>

          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Como posso alugar?</Question>
          <Answer>
            Agende seu horário <a href="https://wa.me/message/APZHBOCKQT5SE1" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">clicando aqui</a> e receba a atenção presencial de nossas personal shoppers, conhecendo de perto e experimentando os looks de nosso acervo no shopping Cidade Jardim, em São Paulo. Está sem tempo? Para clientes residentes em São Paulo, é possível contar com o serviço de aluguel através do Whatsapp, tendo as peças selecionadas entregues na porta de sua casa.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>A Take Me aceita as peças que eu não uso mais? </Question>
          <Answer>
            Sim. <a href="https://wa.me/message/APZHBOCKQT5SE1" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">Clique aqui</a> para enviar pelo Whatsapp imagens das peças que você não usa mais para uma avaliação rápida e sem custo. Caso sua peça seja aprovada, ela passará por uma análise presencial em nosso acervo e poderá integrar nossa curadoria de venda. Quer fazer o bem? Dentro da seleção Give Me, aceitamos peças de doação que terão o valor integral revertido para a causa social de sua preferência. Em nossa comunidade de doadoras, os nomes permanecem anônimos, jogando luz somente para a boa ação que está sendo feita.</Answer>
        </div>
        <Separator />
        <div>
          <Question>Quero devolver uma peça comprada. Como funciona? </Question>
          <Answer>
            Após uma compra realizada presencialmente em nosso acervo, você terá 7 dias úteis para devolvê-la. Se sua compra aconteceu online, o prazo para devolução é o mesmo dia em que a peça foi entregue. É importante lembrar: no improvável caso de você decidir devolver uma peça, ela deve retornar com a etiqueta afixada, no mesmo estado de conservação pelo qual prezamos antes de colocá-la à venda.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Tenho peças para desapegar. Como eu faço?</Question>
          <Answer>
            Trabalhamos com contrato de consignação. Avaliamos as peças
            pessoalmente e te enviamos uma planilha com sugestão de valores que
            você receberá quando a peça for vendida. Você concordando com os
            valores, fazemos o contrato e enviamos por e-mail para assinatura
            via DocuSign.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Quanto vocês pagam por peça?</Question>
          <Answer>
            Cada peça recebe um valor de acordo com a avaliação levando em
            consideração a marca, estado de conservação, tipo de tecido,
            coleção, entre outros quesitos.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Qual a porcentagem de vocês?</Question>
          <Answer>
            A porcentagem é aproximadamente 50%, também dependendo da avaliação
            de cada peça. Te enviamos já o valor final de que você irá receber.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Posso mandar foto para vocês avaliarem?</Question>
          <Answer>
            Infelizmente não fazemos avaliações por foto. Precisamos estar com a
            peça em mãos para analisar o real estado de conservação e se,
            necessário, fazer a autenticação. Mas, para adiantar, você pode
            mandar fotos para analisarmos se temos interesse na peça.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>A peça fica para vender ou para alugar?</Question>
          <Answer>
            Nossa intenção é pagar a fornecedora o mais rápido possível. Sendo
            assim, se acharmos que a peça tem maior potencial de aluguel do que
            de venda, deixamos ela disponível para aluguel e compramos o vestido
            de você assim que ele for alugado pela primeira vez. Caso a peça não
            tenha procura no aluguel, disponibilizamos para venda em nossos
            diferentes canais.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>Não sou de São Paulo. Como faço?</Question>
          <Answer>
            Se você não mora em São Paulo entre em contato conosco para podermos
            encontrar a melhor solução para você.
          </Answer>
        </div>
        <Separator />
        <div>
          <Question>
            Não estou vendo minha peça no site, ela foi vendida?
          </Question>
          <Answer style={{ paddingBottom: "10px" }}>
            Não necessariamente. A peça pode estar com o time de concierges do
            Shopping Cidade Jardim, com alguma cliente que tenha interesse em
            comprar ou em manutenção (lavanderia, costureira, sapateiro). Há
            também a possibilidade de a peça ter sido vendida, mas ainda estar
            dentro do prazo de devolução pela cliente que a comprou. Quando
            alguma peça é vendida, enviamos por e-mail, ao final do mês, um
            relatório indicando quais itens foram vendidos, o valor a receber e
            o comprovante de pagamento.
          </Answer>
        </div>
      </FAQContainer>
      <Follow />
    </Layout>
  );
};

export default Faq;
