import React from "react";
import { Button, Title, CenteredDiv } from "./styles";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill, BsClockFill, BsLockFill } from "react-icons/bs";

const PagamentoCartao = ({ dados }) => {
    const navigate = useNavigate();
    let description = null;
    let status = null;
    let message = null;
    let statusColor = "var(--Marrom, #c0b6ad)";
    let Icon = null;

    if (dados) {
        description = dados.charges[0].description ?? '';
        message = dados.charges[0].payment_response.message ?? '';
        status = dados.charges[0].status ?? '';

        if (status.toLowerCase() === "paid") {
            statusColor = "green";
            Icon = BsCheckCircleFill;
        } else if (status.toLowerCase() === "declined") {
            statusColor = "red";
            Icon = BsLockFill;
        }
        else {
            Icon = BsClockFill;
        }
    }

    return (
        <CenteredDiv>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%', 
            }}>
                <Title>Seu pedido foi realizado com sucesso!</Title>
                <p style={{ fontSize: "14px" }}>
                    Acompanhe a situação do seu pedido
                </p>
                <Button onClick={() => navigate("/conta?pedido=true")}>
                    Meus Pedidos
                </Button>
                <p style={{ padding: "0", marginBottom: "0" }}>{description}</p>
                <p style={{ color: statusColor, marginLeft: "12px" }}>
                    <Icon /> {message}
                </p>
            </div>
        </CenteredDiv>
    );
};

export default PagamentoCartao;
