import { useState, useCallback } from "react";
import {
  Background,
  Button,
  ButtonContainer,
  Content,
  ItemList,
  ListShop,
  ListBrands,
  MenuDiv1,
  MenuDiv2,
  Span,
  Imagem
} from "./styles";
import { useNavigate } from "react-router-dom";
const Menu = ({ items = [], open = false, background = null, type, onFilterChange }) => {
  const [activeButton, setActiveButton] = useState("mulher");
  const [hoveredItem, setHoveredItem] = useState(null);

  const removeSFromEnd = (text) => {
    if (text.endsWith("s")) {
      return text.slice(0, -1);
    }
    return text;
  };
  const origin = window.location.origin;
  var linkImage;
  if (background) {
    linkImage = background;

    if (background.startsWith(origin)) {
      linkImage = background.substring(origin.length);
    } else {
      linkImage = origin + "/" + background
    }
  }

  const navigate = useNavigate();

  const handleClick = useCallback(
    (item) => {
      if(item === "all"){
        navigate({
          pathname: "/brandlist",
        });
        return;
      }
      onFilterChange(removeSFromEnd(item.text));
      if(item){
        if (item?.text !== "Ver Tudo") {
          navigate({
            pathname: "/shop",
            search: `?search=${removeSFromEnd(item.text)}`,
  
          });
        }
      }
    else {
        navigate({
          pathname: "/brandlist",
          search: ``,
        });
      }
    },
    [navigate, onFilterChange]
  );


 

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const getSectionItems = (section) => {
    return section.map((item, index) => (
      
      <ItemList
        key={index}
        onMouseEnter={() => setHoveredItem(item.text)}
        onMouseLeave={() => setHoveredItem(null)}
        style={{
          fontWeight:
            hoveredItem === item.text || (item.text === "VER TUDO" && activeButton === "mulher")
              ? 500
              : 100,
        }}
        onClick={() => handleClick(item)}
      >
        {item.text}
      </ItemList>
    ));
  };



  return (
    <MenuDiv1 open={open && items.length > 0}>
      <MenuDiv2>
        <Content>
          {/*  {type === "shop" && (
            <ButtonContainer>
              <Button
                active={activeButton === "mulher"}
                onClick={() => handleButtonClick("mulher")}
              >
                Shop Mulher
              </Button> 
              {/* <Button
                active={activeButton === "homem"}
                onClick={() => handleButtonClick("homem")}
              >
                Shop Homem
              </Button> 
            </ButtonContainer>
          )}
          */}
          {type === "brands" && (
            <ButtonContainer>
               
              <Button
                active={activeButton === "marcas"}
                onClick={() => handleButtonClick("marcas")}
              >
                Marcas
              </Button>
            
            </ButtonContainer>
          )}

          {items.map((section, index) =>
            type === "shop" ? (
              <ListShop key={index}>
                <Span>{section.title}</Span>
                {getSectionItems(section.items)}
              </ListShop>
            ) : (
              <ListBrands key={index}>
                {getSectionItems(section.items)}
                <Span  style={{
          fontWeight:
            hoveredItem === section.items[7]?.textAll || (section.items[7]?.textAll === "VER TUDO")
              ? 400
              : 100,
              fontSize:"20px",
              cursor:"pointer",
        }}
        onClick={() => handleClick("all")}
        >{section.items[7]?.textAll}</Span>

              </ListBrands>
            )
          )}
        </Content>

        <Background>
          {background && (
            <Imagem
              alt="background-menu"
              src={linkImage ?? ""}
            />
          )}
        </Background>
      </MenuDiv2>
    </MenuDiv1>
  );
};

export default Menu;
