import styled from "styled-components";

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ItemFooter = styled.div`
  background-color: #f7f4ec;
  padding: 30px;
  padding-bottom: 20%;

  @media (max-width: 768px) {
    padding: 20px 0px 70px 15px;
  }
`;
export const ItemFooterDiv = styled.div`
  background-color: #f7f4ec;
  padding: 30px;
  padding-bottom: 20%;
  border-right: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ItemFooterDivMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    background-color: #f7f4ec;
    padding: 20px 20px 20px 10px;
    border-right: none;
    display: block;
  }
`;

export const ItemFooterMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    background-color: #f7f4ec;
    padding: 20px 20px 20px 10px;
    border-right: none;
    display: block;
    border-bottom: 1px solid;
  }
`;

export const ConteudoFooter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-family: 'Thasadith', sans-serif;
  a {
    text-decoration: none;
    color: #000;

    :hover {
      font-weight: bold;
    }
  }
    :hover {
      font-weight: bold;
    }

  @media (max-width: 768px) {
    font-size: 12px;

  }
`;

export const HeaderSpan = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size:15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderLocal = styled.div`
  height: 50px;
  font-size:12px;
  display:flex;
  background-color:#f7f4ec;
`;

export const HeaderSpanMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-top: 1%;
    font-size: 12px;
  }
`;

export const LogoFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    img {
      width: 40%;
    }
  }
`;

export const ModalAddressBarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalAddressBarContent = styled.div`
  position: absolute;
  right: 0;
  z-index: 9999;
  height: 100%;
  width: 33%;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: translateX(${({ isOpen }) => (isOpen ? "0%" : "100%")});
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1);
`;