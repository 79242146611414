import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import configs from "config";
import axios from "axios";
import { Container, Titles, Quadrant, QuadrantTitle, QuadrantDescription, ButtonFilter } from "./styles";
import Layout from "../Layouts/Layout";

const BrandList = () => {
  const [brands, setBrand] = useState([]);
  const [visibleBrands, setVisibleBrands] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialFilters = async () => {
      try {
        const response = await axios.get(configs.api + "brandList");
        setBrand(response.data);
      } catch (error) {
        console.error("Error fetching initial filters:", error);
      }
    };

    fetchInitialFilters();
  }, []);

  const organizeBrandsByLetter = useCallback(() => {
    const organizedBrands = {};
    const numberBrands = [];

    brands.forEach((brand) => {
      const firstChar = brand.description.charAt(0).toUpperCase();
      if (isNaN(firstChar)) {
        if (!organizedBrands[firstChar]) {
          organizedBrands[firstChar] = [];
        }
        organizedBrands[firstChar].push(brand);
      } else {
        numberBrands.push(brand);
      }
    });

    const sortedLetters = Object.keys(organizedBrands).sort();
    const sortedOrganizedBrands = {};

    sortedLetters.forEach((letter) => {
      sortedOrganizedBrands[letter] = organizedBrands[letter];
    });

    if (numberBrands.length > 0) {
      sortedOrganizedBrands["#"] = numberBrands;
    }

    return sortedOrganizedBrands;
  }, [brands]);

  useEffect(() => {
    const organizedBrands = organizeBrandsByLetter();
    const initialVisibleBrands = Object.keys(organizedBrands).reduce((acc, letter) => {
      acc[letter] = 5;
      return acc;
    }, {});
    setVisibleBrands(initialVisibleBrands);
  }, [brands, organizeBrandsByLetter]);

  const showMore = (letter) => {
    setVisibleBrands((prev) => ({
      ...prev,
      [letter]: prev[letter] + 10,
    }));
  };

  const isMobile = () => window.innerWidth <= 768;

  const renderQuadrants = () => {
    const organizedBrands = organizeBrandsByLetter();

    return Object.keys(organizedBrands).map((letter) => (
      <Quadrant key={letter}>
        <QuadrantTitle>{letter}</QuadrantTitle>
        {isMobile() && organizedBrands[letter].slice(0, visibleBrands[letter]).map((brand) => (
          <QuadrantDescription
            key={brand.id}
            onClick={() => {
              const encodedBrandDescription = encodeURIComponent(brand.description);
              navigate({
                pathname: '/shop',
                search: `?search=${encodedBrandDescription}&brandlist=true`,
              })
            }
            }

          >
            {brand.description}
          </QuadrantDescription>
        ))}
        {isMobile() && visibleBrands[letter] < organizedBrands[letter].length && (
          <ButtonFilter
            style={{
              width: "70px",
              height: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
            onClick={() => showMore(letter)}
          >
            Ver mais
          </ButtonFilter>
        )}
        {!isMobile() && organizedBrands[letter].map((brand) => (
          <QuadrantDescription
            key={brand.id}
            onClick={() => {
              const encodedBrandDescription = encodeURIComponent(brand.description);
              navigate({
                pathname: '/shop',
                search: `?search=${encodedBrandDescription}&brandlist=true`,
              })
            }
            }
          >
            {brand.description}
          </QuadrantDescription>
        ))}
      </Quadrant>
    ));
  };

  return (
    <Layout>
      <Container>
        <Titles>Marcas</Titles>
        {renderQuadrants()}
      </Container>
    </Layout>
  );
};

export default BrandList;
