import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeVintage from "containers/HomeVintage";
import Novidades from "containers/Novidades";
import Shop from "containers/Shop";
import Sale from "containers/Sale";
import SellWithUs from "containers/SellWithUs";
import Faq from "containers/Faq";
import GiveMe from "containers/GiveMe";
import Termos from "containers/Termos";
import Conta from "containers/Conta";
import Cart from "containers/Cart";
import ProductDetails from "containers/ProductDetails";
import Checkout from "containers/Checkout";
import ResetPassword from "containers/ResetPassword";
import OrderDetails from "components/OrderDetails";
import TakeMeAround from "containers/TakeMeAround";
import TakeMeSelection from "containers/TakeMeSelection";
import BrandList from "containers/BrandList";
import ConfirmEmail from "containers/ConfirmEmail";
import About from "containers/About";

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact index path="/" element={<HomeVintage />} />
        <Route exact index path="/novidades" element={<Novidades />} />
        <Route exact index path="/shop" element={<Shop />} />
        <Route exact index path="/sale" element={<Sale />} />
        <Route exact index path="/sellwithus" element={<SellWithUs />} />
        <Route exact index path="/giveme" element={<GiveMe />} />
        <Route exact index path="/conta" element={<Conta />} />
        <Route exact index path="/order/:id" element={<OrderDetails />} />
        <Route
          exact
          path="/password/reset/:token/"
          element={<ResetPassword />}
        />
         <Route
          exact
          path="/confirm/email/:token/"
          element={<ConfirmEmail />}
        />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route exact index path="/cart" element={<Cart />} />
        <Route exact index path="/checkout" element={<Checkout />} />
        <Route exact index path="/faq" element={<Faq />} />
        <Route exact index path="/termos" element={<Termos />} />
        <Route exact index path="/about" element={<About />} />
        <Route exact index path="/around" element={<TakeMeAround />} />
        <Route exact index path="/selection" element={<TakeMeSelection />} />
        <Route exact index path="/brandlist" element={<BrandList />} />
        
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
