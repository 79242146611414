import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height:700px;
  margin:auto auto auto;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1280px;
  justify-content: center;
  @media (max-width: 768px) {
  padding: 10px;
  margin:0 0 auto;
  flex-wrap: wrap;
  gap: 5px;
  width: 70%;
  justify-content: center;
}
 
`;

export const Quadrant = styled.div`
flex: 1 1 200px; 
margin-bottom:40px;
max-height: 250px; 
overflow-y: scroll;

@media (max-width: 768px) {
  padding: 10px;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  border-bottom: 1px solid black;

}
`;

export const QuadrantTitle = styled.h3`
  font-size: 25px;
  margin-bottom: 10px;
  margin-top:0;
  color: #333;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background-color: #fff; 

  z-index: 1; 
`;

export const Titles = styled.div`
  color: black;
  border-bottom: 1px solid #000;
  width: 100%;
  font-family: Thasadith, sans-serif;
  font-size: 40px;
  font-style: normal;
  line-height: normal;
`;

export const QuadrantDescription = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #333;
  font-family: Thasadith;
  font-style: normal;
  line-height: normal;
  font-weight:700;
  &:hover {
    font-weight: 1000;
    color: green;
  }
`;

export const ButtonFilter = styled.div`
  display:none;
 
  @media (max-width: 768px) {
    background-color: #d8dfc1;
    width: 100%;
    font-family: Thasadith;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    display:flex;
    padding:20px;
    border-radius:15px;
    justify-content:space-between;
    align-items:center;
  }
`;
