import styled from "styled-components";

export const BackgroundImage = styled.div`
  width: 100%;
  height: 1000px;
  background-color: #000;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  ${({ border }) =>
    border ? "border-bottom: 1px solid #000; border-top: 1px solid #000" : ""}
`;

export const TitleDiv = styled.div`
  position: absolute;
  bottom: 20px;
  margin-left: 30px;

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    bottom: 20%;
  }
`;

export const Title = styled.span`
  font: normal normal normal 36px ${(props) => props.theme.fontTitle.family};

  @media (max-width: 768px) {
    font: normal normal normal 30px ${(props) => props.theme.fontTitle.family};
  }
`;

export const ShopNow = styled.span`
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
