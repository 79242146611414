import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    Cookies.get("authToken") !== undefined
  );
  const [userName, setUserName] = useState("");
  const [id, setId] = useState("");

  const login = (token, name, id) => {
    Cookies.set("authToken", token, { expires: 7, secure: true });
    Cookies.set("id", id, { expires: 7, secure: true });
    Cookies.set("name", name, { expires: 7, secure: true });
    setIsUserLoggedIn(true);
    setUserName(name);
    setId(id);
  };

  const logout = useCallback(() => {
    Cookies.remove("authToken");
    Cookies.remove("id");
    Cookies.remove("name");
    localStorage.removeItem('cart-guest');
    setIsUserLoggedIn(false);
    setUserName("");
    setId("");
    localStorage.removeItem("cachedLoginData");
    window.location.href = "/";
  }, []);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    const id = Cookies.get("id");
    const name = Cookies.get("name");

    if (authToken) {
      const decodedToken = JSON.parse(atob(authToken.split('.')[1]));
      const expirationTimeInSeconds = decodedToken.exp;
      const expirationDate = new Date(expirationTimeInSeconds * 1000);
      const currentDate = new Date();
      if (currentDate > expirationDate) {
        logout();
      } else {
        setIsUserLoggedIn(true);
        setUserName(name);
        setId(id);
      }
    }
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn,
        userName,
        id,
        login,
        logout,
        setUserName,
        setId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
