import {
  ContainerText,
  Header,
  HeaderTitle,
  Text,
  CardContainer,
  Card,
  Title,
  Description,
  NumberCircle,
  IconCardContainer,
  IconCard,
  Icon,
  ButtonDiv,
  ImageContainer,
  Image,
  BackgroundImage,
  TextDiv,
  ButtonSt,
  IconTitle,
  Content,
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import Icon1 from "../../assets/imgs/icon1.png";
import Icon2 from "../../assets/imgs/icon2.png";
import Button from "components/Button";
import { Link } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";
const SellWithUs = () => {
  const [image1] = useImageProgressive("assets/imgs/downgrade/model_walk_edited.jpg"    , "assets/imgs/model_walk_edited.jpg");

  return (
    <Layout>
      <Content border>
        <ImageContainer border>
          <Image borderRight>
            <BackgroundImage
              image={image1}
            />
          </Image>
          <TextDiv>
            <Header>
              <HeaderTitle>SELL WITH US</HeaderTitle>
            </Header>
            <Text>
              Encontre novos caminhos para as peças
              <br /> que não fazem mais sentido no seu guarda-roupa.
            </Text>
            <Button>
              <Link
                to="https://wa.me/message/APZHBOCKQT5SE1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#000" }}
                as="div" // Renderiza o Link como uma div
              >
                AGENDE UM HORÁRIO
              </Link>
            </Button>
          </TextDiv>
        </ImageContainer>
        <ContainerText>
          <Header>
            <HeaderTitle>
              POR QUE VENDER
              <br /> COM A TAKE ME?
            </HeaderTitle>
          </Header>
          <Text>
            Seus desapegos icônicos encontram um novo propósito no nosso acervo
            com a curadoria que só a TAKE ME faz. Vender com a TAKE ME é fácil e
            seguro. Aqui, suas peças ganham novos caminhos no nosso acervo e
            colaboram para um jeito muito mais consciente de consumir!
          </Text>

          <CardContainer>
            <Card>
              <NumberCircle>01</NumberCircle>
              <Title>É confiável</Title>
              <Description>
                A gente cuida de tudo para você.
                <br /> E somos a plataforma exclusiva de Second Hand do CJ
                Fashion.
              </Description>
            </Card>
            <Card>
              <NumberCircle>02</NumberCircle>
              <Title>É sustentável</Title>
              <Description>
                Um novo propósito para aqueles looks que não fazem mais sentido
                para você.
              </Description>
            </Card>
            <Card>
              <NumberCircle>03</NumberCircle>
              <Title>É discreto</Title>
              <Description>
                Atendimento personalizado
                <br /> de acordo com
                <br /> a sua necessidade.
              </Description>
            </Card>
          </CardContainer>

          <Header>
            <HeaderTitle>SELL WITH US</HeaderTitle>
          </Header>
          <Text>
            Parceiras Take Me tem benefícios exclusivos vendendo com a gente.
            <br />
            Vender com a Take Me é fácil e você ainda pode escolher como usar o
            seu saldo conosco.
          </Text>

          <IconCardContainer border>
            <IconCard>
              <Icon src={Icon1} alt="Ícone 1" style={{ width: "25%" }} />
              <IconTitle>BENEFÍCIO 01</IconTitle>
              <Description>
                Transferência direto
                <br /> na sua conta cadastrada
              </Description>
            </IconCard>
            <IconCard>
              <Icon src={Icon2} alt="Ícone 2" />
              <IconTitle>BENEFÍCIO 02</IconTitle>
              <Description>
                Saldo no CJ FASHION com 10%
                <br /> de bônus em cima do valor
              </Description>
            </IconCard>
          </IconCardContainer>

          <ButtonDiv>
            <Link
              to="https://wa.me/message/APZHBOCKQT5SE1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "#000" }}
              as="div" // Renderiza o Link como uma div
            >
              <ButtonSt>QUERO VENDER COM A TAKE ME</ButtonSt>
            </Link>
          </ButtonDiv>
        </ContainerText>
      </Content>
      <Follow />
    </Layout>
  );
};

export default SellWithUs;
