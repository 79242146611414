import React from "react";
import {  InfoPage, Title, Text,  BorderBottom, Container} from "./styles";
// import Ameba from "assets/imgs/Ameba.png";
const Banner = ({ title, description}) => {
  return (
    <Container data-testid="banner-header">
      {/* <BackgroundImage img={imgBanner}></BackgroundImage> */}
      <InfoPage>
        {/* <AmebaImg src={Ameba} alt="Ameba"/> */}
        <Title>{title}</Title>
        <BorderBottom></BorderBottom>
        <Text>
          {description}
        </Text>
      </InfoPage>
    </Container>
  );
};

export default Banner;
