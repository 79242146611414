import {
  ImageContainer,
  Image,
  BackgroundImage,
  Content,
  HeaderTitle,
  TextDiv,
  Header,
  Text
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import useImageProgressive from "hooks/useImageProgressive";

const TakeMeAround = () => {
  const [image1] = useImageProgressive("assets/imgs/downgrade/around.jpg", "assets/imgs/around.jpg");
  return (
    <Layout>
      <Content>
        <ImageContainer border>
          <Image borderRight>
            <BackgroundImage
              image={image1}
            />
          </Image>
          <TextDiv>
            <Header>
              <HeaderTitle>AROUND</HeaderTitle>
            </Header>
            <Text>
              Momentos especiais duram para sempre. A moda bem feita, também. Percorra nossa seleção com milhares de roupas, bolsas, sapatos e acessórios prontos para ser o seu plus-one na próxima ocasião especial circulada no calendário. Através de nosso serviço de aluguel, montar um look inesquecível ficou tão simples como fazer o RSVP. Escolha, experimente, use e devolva quando quiser. Nós cuidamos do resto.
            </Text>
            <Header>
              <HeaderTitle style={{ fontSize: "40px", fontWeight:"500", textShadow: "2px 2px 2px white"}}>COMING SOON...</HeaderTitle>
            </Header>
          </TextDiv>
        </ImageContainer>
      </Content>
      <Follow />
    </Layout>
  );
};

export default TakeMeAround;
