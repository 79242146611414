import styled from "styled-components";

export const Header = styled.div`
  padding: 0 40px;
  border-top: 0px;
  border-bottom: 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 5px;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 15px;
  margin: 60px;
`;

export const Container = styled.div`
  display: flex;
  height: 700px;
  border-top: 1px solid #000;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const Sidebar = styled.div`
  width: 200px;
  margin: 0 70px;

  @media (max-width: 768px) {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: 5%;
  }
`;

export const TitleUser = styled.div`
  font-weight: 400;
  padding: 50px 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const MenuItemHeader = styled.li`
  padding: 10px 50px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "400" : "normal")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  color: ${({ activeColor }) => (activeColor ? "#000" : "#989898")};
  list-style: none;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const MenuItem = styled.li`
  padding: 10px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "400" : "normal")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  color: ${({ activeColor }) => (activeColor ? "#000" : "#989898")};
  list-style: none;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 3px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 50px 20px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 110%;
    padding: 20px;
    margin: auto;
  }
`;

export const Content = styled.div`
  flex: 1;
  font-weight: 400;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  padding: 30px 60px;
  margin-left: 100px;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ButtonMobile = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    padding: 15px 30px;
    background-color: #fff;
    font-size: 14px;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin: 5%;
  }
`;

export const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding-top: 65px;

  @media (max-width: 768px) {
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const AddressBox = styled.div`
  padding: 20px;
  border: 1px solid #000;
  font-weight: 300;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const Address = styled.div`
  margin-bottom:10px;
  font-size:16px;
`;

export const ButtonModal = styled.button`
  display: flex;
  height: 35px;
  font-size: 14px;
  border: 1px solid #000;
  cursor: pointer;
  padding:10px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  background-color: #000;
  color: #fff;
`;
export const AddressEdit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  font-weight: normal;
  font-size: 14px;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 0;
  }
`;

export const PersonalDataContainer = styled.div`
  display: flex;
  border: 1px solid #000;
  padding: 20px;
  margin-top: 65px;
  width:600px;
  @media (max-width: 768px) {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 65%;
  }
`;

export const DataColumn = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DataTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #989898;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const DataValue = styled.div`
  margin-bottom: 10px;
  font-size:16px;
  @media (max-width: 768px) {
    padding-bottom: 25px;
  }
`;

export const TitleAndRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  padding: 0 40px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TitleAndRadioContainerMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 10px;
    padding: 0 40px;
  }
`;

export const Title = styled.div`
  font-weight: 400;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SubTitle = styled.div`
  margin-right: 10px;
  padding-top: 65px;

  @media (max-width: 768px) {
    padding-top: 25px;
    font-size: 14px;
  }
`;

export const Span = styled.div`
  margin-top: 10px;
  font-size: 14px;
  padding-bottom: 7px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RadioButton = styled.input`
  margin-left: 0;
  margin-top: 10px;
  width: 16px;
  height: 16px;
`;

export const TitleContent = styled.div`
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
