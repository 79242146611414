import React, { useState } from "react";
import Layout from "containers/Layouts/Layout";
import { Button, Container, FormReset } from "./styles";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import configs from "config";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const { search } = useLocation();

  const email = new URLSearchParams(search).get("email");

  const [passwordError, setPasswordError] = useState({
    msg: "",
    isInvalid: false,
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    msg: "",
    isInvalid: false,
  });

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setPasswordError({ error: "", isInvalid: false });

    if (!password) {
      setPasswordError({
        msg: "Preenchimento da senha é obrigatório",
        isInvalid: true,
      });
      alert("Preenchimento da senha é obrigatório", { toastId: 1 });
      return;
    }

    if (password.length < 6) {
      setPasswordError({
        msg: "A senha deve ter no mínimo 6 caracteres",
        isInvalid: true,
      });
      alert("A senha deve ter no mínimo 6 caracteres", { toastId: 1 });
      return;
    }

    if (!confirmPassword) {
      setConfirmPasswordError({
        msg: "Preenchimento da confirmação de senha é obrigatório",
        isInvalid: true,
      });
      alert("Preenchimento da senha é obrigatório", { toastId: 1 });
      return;
    }

    if (!email) {
      alert("E-mail não encontrado", { toastId: 1 });
      return;
    }

    if (!token) {
      alert("Token não encontrado", { toastId: 1 });
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError({
        msg: "Senhas inseridas não são iguais",
        isInvalid: true,
      });
      setConfirmPasswordError({
        msg: "Senhas inseridas não são iguais",
        isInvalid: true,
      });
      alert("Senhas inseridas não são iguais", { toastId: 2 });
      return;
    }

    try {
      await axios.post(
        configs.api +"password/reset",
        {
          password,
          token,
          email,
        }
      );

      navigate("/", { replace: true });
      alert("Senha resetada com sucesso", { toastId: 3 });
    } catch (e) {
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.message ? data.message : "Ocorreu um erro";
      alert(msg, { toastId: 3 });
    } finally {
    }
  };

  const handlePassword = (e) => {
    setPasswordError({ error: "", isInvalid: false });
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordError({ error: "", isInvalid: false });
    setConfirmPassword(e.target.value);
  };

  return (
    <Layout>
      <Container>
        <FormReset>
          <span>Redefinir Senha</span>
          <div>
            <input
              value={password}
              text={"Nova senha*:"}
              key={"password"}
              type={"password"}
              autocomplete="false"
              placeholder={"Nova senha*:"}
              isInvalid={passwordError.isInvalid}
              error={{ message: passwordError.msg }}
              onChange={handlePassword}
            />
            <input
              value={confirmPassword}
              text={"Confirme a nova senha*:"}
              key={"password"}
              type={"password"}
              autocomplete="false"
              placeholder={"Confirme a nova senha*:"}
              isInvalid={confirmPasswordError.isInvalid}
              error={{ message: confirmPasswordError.msg }}
              onChange={handleConfirmPassword}
            />
            <p>*Campos obrigatórios</p>
            <Button
              type={"button"}
              fullwidth={"true"}
              bgColor={"#F1B7A9"}
              onClick={handleSubmit}
            >
              Redefinir Senha
            </Button>
          </div>
        </FormReset>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
