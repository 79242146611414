import styled from "styled-components";

export const FilterContainer = styled.div`
  width: 100%;
  gap: 2px;
  display: flex;

`;

export const ListaFiltro = styled.div`
  height: 40px;
  background: #d8dfc1;
  width: 100%;
  display: flex;
  justify-content: center;
  position:relative;
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0px;
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0px;
  }


  @media (max-width: 768px) {
    display: none;
  }
`;

export const Categorias = styled.div`
  font-family: Thasadith;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-left: 10px;
  margin-right:10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Submenu = styled.div`
  padding: 10px;
  margin-bottom: 25px;
`;

export const SubmenuTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  padding-left: 15px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const RangeInput = styled.input`
  width: 80%;
  margin-left:20px;
`;
export const CheckboxLabel = styled.label`
  display: flex;
  padding-left:5px;
  padding-right:5px;
  align-items: center;
  cursor: pointer;
  font-family: Thasadith;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
`;

export const CheckboxInput = styled.input`
  background-color: black;
  border: 2px solid black;
  &:checked {
    background-color: black;
    border: 2px solid black;
  }
`;
export const SubmenuScrollable = styled.div`
  max-height: 200px;
  min-height: 120px;
  padding-top:10px;
  width:100%;
  margin-top:40px;
  overflow-y: auto;
  position: absolute;
  box-sizing: border-box;
  background-color: #f7f4ec;
  z-index: 1;
  border-radius: 0 0 0px 12px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const PriceBox = styled.div`
  align-items: center;
  cursor: pointer;
  background: #d8dfc1;
  border: 1px solid #d8dfc1;
  font-family: Thasadith;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
`;

export const PriceBoxInput = styled.input`
  display: flex;
  justify-content: center;
  width: 30%;
  padding: 15px 20px;
  border: 1px solid #989898;
  border-radius: 25px;
  margin-bottom: 25px;
`;

export const PriceBoxRange = styled.input.attrs({ type: "range" })`
  width: 80%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 1px;
  background: #989898;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 25px;
    background: #fff;
    border: 1px solid #000;
  }
`;
export const ButtonFilter = styled.div`
 display:none;
  @media (max-width: 768px) {
    background-color: #d8dfc1;
    width: 100%;
    margin:10px;
    margin-top:10px;
    font-family: Thasadith;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    display:flex;
    padding:20px;
    border-radius:15px;
    justify-content:space-between;
    align-items:center;
  }
`;

export const ButtonPrice = styled.div`
  width: 70%;
  font-family: Thasadith;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  display:flex;
  padding:10px;
  margin:auto;
  margin-bottom:10px;
  background-color: black;
  color: white;
  border-radius:15px;
  justify-content:space-between;
  align-items:center;
`;
