import React, { useState, useEffect } from "react";
import Layout from "../Layouts/Layout";
import {
  Address,
  AddressBox,
  AddressEdit,
  AddressGrid,
  // Button,
  // ButtonMobile,
  ButtonModal,
  Container,
  Content,
  ContentContainer,
  DataColumn,
  DataTitle,
  DataValue,
  Header,
  Menu,
  MenuItem,
  MenuItemHeader,
  PersonalDataContainer,
  // RadioButton,
  Sidebar,
  // Span,
  // SubTitle,
  // Title,
  // TitleAndRadioContainer,
  // TitleAndRadioContainerMobile,
  TitleContent,
  TitleUser,
} from "./styles";
import { useAuth } from "context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
// import BoxInformation from "components/BoxInformation";
import MyOrders from "components/MyOrders";
import configs from "config";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "components/Loader";
import Modal from "react-modal";
import { BsInfoCircle } from "react-icons/bs";
const Conta = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("");
  // const [receiveUpdates, setReceiveUpdates] = useState(true);
  const { logout, userName, id } = useAuth();
  const [dataUsers, setDataUsers] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setisEditing] = useState(false);
  const [newNome, setNewNome] = useState(dataUsers ? dataUsers.name : "");
  const [newTelefone, setNewTelefone] = useState(
    dataUsers ? dataUsers.numero_celular : ""
  );
  const [searchParams] = useSearchParams();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const openLogoutConfirmation = () => {
    setShowLogoutConfirmation(true);
  };
  const openInfo = () => {
    setShowInfo(true);
  };
  useEffect(() => {
    if (searchParams.get("pedido")) {
      setActiveMenuItem("meus-pedidos");
    }
  }, [searchParams, setActiveMenuItem]);
  const handleEditClick = async () => {
    if (isEditing) {
      try {
        const token = Cookies.get("authToken");
        const parts = token.split(".");
        const secondPartBase64 = parts[1];
        const decodedSecondPart = atob(secondPartBase64);
        const payload = JSON.parse(decodedSecondPart);
        console.log(payload);
        const updatedData = {
          token: token,
          name: newNome,
          numero_celular: newTelefone,
          email: dataUsers.email,
          CPF_CNPJ: dataUsers.CPF_CNPJ,
        };
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.post(
          configs.api + "client_site/update",
          updatedData,
          { headers }
        );

        if (response.status === 200) {
          setDataUsers(updatedData);
          const tokenParts = token.split(".");
          const payloadBase64 = tokenParts[1];
          const payload = JSON.parse(atob(payloadBase64));
          const tokenID = payload.id;

          console.log("ID do Token JWT:", tokenID);
          console.log("ID do Token JWT:", userName);

          setisEditing(false);
        } else {
          console.error("Erro ao atualizar user data:", response);
        }
      } catch (error) {
        console.error("Erro ao atualizar user data:", error);
      }
    } else {
      setisEditing(true);
    }
  };
  const confirmLogout = () => {
    logout();
    localStorage.removeItem("cachedLoginData");
    navigate("/");
  };
  const handleMenuItemClick = (menuItem) => {
    if (menuItem === "sair") {
      logout();
      localStorage.removeItem("cachedLoginData");
      navigate("/");
    } else {
      setActiveMenuItem(menuItem);
    }
  };

  // const handleButtonClick = () => {
  //   //
  // };
  const url = `${configs.api}client/` + id;

  useEffect(() => {
    const dadosPessoais = async () => {
      try {
        const token = Cookies.get("authToken");

        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(url, { headers });
        const fetchedDataUsers = response.data;
        setDataUsers(fetchedDataUsers);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    dadosPessoais();
  }, [url]);

  // const handleReceiveUpdatesChange = (e) => {
  //   setReceiveUpdates(e.target.checked);
  // };

  const renderContent = () => {
    switch (activeMenuItem) {
      case "cartoes":
        return (
          <div>
            <TitleContent>CARTÕES</TitleContent>
            <AddressGrid>
              <AddressBox>
                <Address>Visa</Address>
                <Address>0000 0000 0000 0000</Address>
                <Address>VALIDADE 07/28</Address>
                <Address>CPF 456654456-65</Address>
                <AddressEdit>Editar</AddressEdit>
              </AddressBox>
              <AddressBox>
                <Address>Visa</Address>
                <Address>0000 0000 0000 0000</Address>
                <Address>VALIDADE 07/28</Address>
                <Address>CPF 456654456-65</Address>
                <AddressEdit>Editar</AddressEdit>
              </AddressBox>
            </AddressGrid>
          </div>
        );
      case "dados-pessoais":
        return (
          <div>
            <TitleContent>DADOS PESSOAIS</TitleContent>
            <PersonalDataContainer>
              <DataColumn>
                <DataTitle>Nome</DataTitle>
                {isEditing ? (
                  <input
                    type="text"
                    value={newNome}
                    onChange={(e) => setNewNome(e.target.value)}
                  />
                ) : (
                  <DataValue>{dataUsers.name}</DataValue>
                )}
                <DataTitle>CPF</DataTitle>
                <DataValue>{dataUsers.CPF_CNPJ}</DataValue>
                <DataTitle>Email</DataTitle>
                <DataValue>{dataUsers.email}</DataValue>
              </DataColumn>
              <DataColumn>
                <DataTitle>Telefone</DataTitle>
                {isEditing ? (
                  <input
                    type="text"
                    value={newTelefone}
                    onChange={(e) => setNewTelefone(e.target.value)}
                  />
                ) : (
                  <DataValue>{dataUsers.numero_celular}</DataValue>
                )}
                <AddressEdit
                  onClick={handleEditClick}
                  style={{ cursor: "pointer" }}
                >
                  {isEditing ? "Salvar" : "Editar"}
                </AddressEdit>
              </DataColumn>
            </PersonalDataContainer>
          </div>
        );
      case "enderecos":
        return (
          <div>
            <TitleContent>ENDEREÇOS <BsInfoCircle onClick={openInfo} style={{ cursor: "pointer" }}
            /></TitleContent>
            <AddressGrid>
              <AddressBox>
              <DataTitle>Endereço</DataTitle>
                <Address>
                  {dataUsers.user_logradouro}, {dataUsers.user_numero}
                </Address>
                <DataTitle>BAIRRO/CIDADE/ESTADO</DataTitle>
                <Address>
                  {dataUsers.user_bairro} - {dataUsers.user_municipio} -{" "}
                  {dataUsers.user_estado}
                </Address>
                <DataTitle>CEP</DataTitle>
                <Address>{dataUsers.user_cep}</Address>
                <DataTitle>COMPLEMENTO</DataTitle>
                <Address>{dataUsers.user_complementologradouro}</Address>
                <DataTitle>PAÍS</DataTitle>
                <Address>Brasil</Address>
              </AddressBox>
            </AddressGrid>
          </div>
        );
      case "autenticacao":
        return (
          <div>
            <TitleContent>AUTENTICAÇÃO</TitleContent>
          </div>
        );

      case "meus-pedidos":
        return (
          <div>
            <TitleContent>MEUS PEDIDOS</TitleContent>
            <MyOrders user_id={id} />
          </div>
        );
      // case "meus-favoritos":
      //   return (
      //     <div>
      //       <TitleContent>MEUS FAVORITOS</TitleContent>
      //       <BoxInformation title="Nenhum produto em sua lista." />
      //     </div>
      //   );
      case "sair":
        return null;
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Header>
        <MenuItemHeader
          active={activeMenuItem === "dados-pessoais"}
          activeColor={activeMenuItem === "dados-pessoais"}
          onClick={() => handleMenuItemClick("dados-pessoais")}
        >
          PERFIL
        </MenuItemHeader>
        <MenuItemHeader
          active={activeMenuItem === "meus-pedidos"}
          activeColor={activeMenuItem === "meus-pedidos"}
          onClick={() => handleMenuItemClick("meus-pedidos")}
        >
          MEUS PEDIDOS
        </MenuItemHeader>
        {/* <MenuItemHeader
          active={activeMenuItem === "meus-favoritos"}
          activeColor={activeMenuItem === "meus-favoritos"}
          onClick={() => handleMenuItemClick("meus-favoritos")}
        >
          MEUS FAVORITOS
        </MenuItemHeader> */}
      </Header>

      {showLogoutConfirmation && (
        <div>
          <Modal
            isOpen={showLogoutConfirmation}
            onRequestClose={() => setShowLogoutConfirmation(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                height: "150px",
                width: window.innerWidth > 1068 ? "390px" : "230px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                borderRadius: "20px",
                border: "1px solid #000",
              },
            }}
          >
            <p> Tem certeza de que deseja sair?</p>
            <div style={{ display: "flex", gap: "10px" }}>
              <ButtonModal onClick={confirmLogout}>Confirmar</ButtonModal>
              <ButtonModal
                style={{ color: "#000", backgroundColor: "#fff" }}
                onClick={() => setShowLogoutConfirmation(false)}
              >
                Cancelar
              </ButtonModal>
            </div>
          </Modal>
        </div>
      )}

      {showInfo && (
        <div>
          <Modal
            isOpen={openInfo}
            onRequestClose={() => setShowInfo(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                height: "150px",
                width: window.innerWidth > 768 ?  "350px" : "250px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                borderRadius: "20px",
                border: "1px solid #000",
              },
            }}
          >
            <p style={{ textAlign: "center" }}>Para editar o endereço, entre em contato com o administrador.</p>
            <div style={{ display: "flex", gap: "10px" }}>
              <ButtonModal
                style={{ color: "#000", width: "80px", backgroundColor: "#fff" }}
                onClick={() => setShowInfo(false)}
              >
                Ok
              </ButtonModal>
            </div>
          </Modal>
        </div>
      )}
      {isLoading ? (
        <>
          <Loader style={{ display: "flex", alignItems: "center" }} />
        </>
      ) : (
        <Container>
          <Sidebar>
            <TitleUser>OLÁ, {dataUsers.name.toUpperCase()}!</TitleUser>
            {dataUsers && (
              <Menu>
                {/* <MenuItem
              active={activeMenuItem === "cartoes"}
              activeColor={activeMenuItem === "cartoes"}
              onClick={() => handleMenuItemClick("cartoes")}
            >
              Cartões
            </MenuItem> */}
                <MenuItem
                  active={activeMenuItem === "dados-pessoais"}
                  activeColor={activeMenuItem === "dados-pessoais"}
                  onClick={() => handleMenuItemClick("dados-pessoais")}
                >
                  Dados Pessoais
                </MenuItem>
                <MenuItem
                  active={activeMenuItem === "enderecos"}
                  activeColor={activeMenuItem === "enderecos"}
                  onClick={() => handleMenuItemClick("enderecos")}
                >
                  Endereços
                </MenuItem>
                {/* <MenuItem
              active={activeMenuItem === "autenticacao"}
              activeColor={activeMenuItem === "autenticacao"}
              onClick={() => handleMenuItemClick("autenticacao")}
            >
              Autenticação
            </MenuItem> */}
                <MenuItem
                  active={activeMenuItem === "meus-pedidos"}
                  activeColor={activeMenuItem === "meus-pedidos"}
                  onClick={() => handleMenuItemClick("meus-pedidos")}
                >
                  Meus Pedidos
                </MenuItem>
                {/* <MenuItem
              active={activeMenuItem === "meus-favoritos"}
              activeColor={activeMenuItem === "meus-favoritos"}
              onClick={() => handleMenuItemClick("meus-favoritos")}
            >
              Meus Favoritos
            </MenuItem> */}
                <MenuItem
                  active={activeMenuItem === "sair"}
                  activeColor={activeMenuItem === "sair"}
                  onClick={openLogoutConfirmation} // Use a função de confirmação em vez do logout direto
                >
                  Sair
                </MenuItem>
              </Menu>
            )}
          </Sidebar>
          <ContentContainer>
            <Content>{renderContent()}</Content>

            {/* {activeMenuItem === "cartoes" && (
              <Button onClick={handleButtonClick}>ADICIONAR CARTÃO</Button>
            )}

            {activeMenuItem === "enderecos" && (
              <Button onClick={handleButtonClick}>ADICIONAR ENDEREÇOS</Button>
            )} */}

            {/* {activeMenuItem === "dados-pessoais" && (
              <TitleAndRadioContainer>
                <Title>NEWSLETTER</Title>
                <SubTitle>Deseja receber e-mails com promoções?</SubTitle>
                <div style={{ display: "flex" }}>
                  <RadioButton
                    type="radio"
                    checked={receiveUpdates}
                    onChange={handleReceiveUpdatesChange}
                  />
                  <Span>Quero receber e-mails com promoções.</Span>
                </div>
              </TitleAndRadioContainer>
            )} */}
          </ContentContainer>
          {/* {activeMenuItem === "cartoes" && (
            <ButtonMobile onClick={handleButtonClick}>
              ADICIONAR CARTÃO
            </ButtonMobile>
          )}
          {activeMenuItem === "enderecos" && (
            <ButtonMobile onClick={handleButtonClick}>
              ADICIONAR ENDEREÇOS
            </ButtonMobile>
          )} */}
          {/* {activeMenuItem === "dados-pessoais" && (
            <TitleAndRadioContainerMobile>
              <Title>NEWSLETTER</Title>
              <SubTitle>Deseja receber e-mails com promoções?</SubTitle>
              <div style={{ display: "flex" }}>
                <RadioButton
                  type="radio"
                  checked={receiveUpdates}
                  onChange={handleReceiveUpdatesChange}
                />
                <Span>Quero receber e-mails com promoções.</Span>
              </div>
            </TitleAndRadioContainerMobile>
          )} */}
        </Container>
      )}
    </Layout>
  );
};

export default Conta;
