import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height:100vh;
  position: absolute;
  z-index: 9999;
  @media (max-width: 1068px) {
    width: 305%;
    display: flex;
    height: 100%;
    overflow:auto;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 9999;
  }
`;
export const Content = styled.div`
  width:80%;
  display:flex;
  justify-content:center;
  padding-top:20px;
  height:auto;
  @media(max-width: 1068px){
    width:100%;
    display:flex;
    justify-content:center;
  overflow:auto;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  z-index: 9999;

  
  @media (max-width: 1068px) {
    width: 80%;
    display: flex;
    height: auto;
    
  }
`;

export const DivInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 60px;
  width: 100%;
  padding-top: 5px;

  input {
    height: 35px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 700;
    font-family: Thasadith;
    font-style: normal;
    line-height: normal;
    &::placeholder {
      color: #000;
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    input {
      height: 25px;
      padding-left: 10px;
      font-size: 14px;
      font-weight: 700;
      font-family: Thasadith;
      font-style: normal;
      line-height: normal;
      &::placeholder {
        color: #000;
        font-size: 14px;
      }
    }
  }
`;

export const Button2 = styled.button`
  display: flex;
  border: 1px solid rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 50px;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font: normal normal normal 12px ${(props) => props.theme.font.family};
`;
export const TitleDp = styled.span`
  font-weight: 400;


`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  p {
    font-size: 14px;
  }

  span {
    font-size: 14px;
    padding-left: 5px;
  }

  @media (max-width: 768px) {
    margin-top: 0px;

    p {
      text-align: start;
      padding-left: 10px;
    }
  }
`;

export const InputRadio = styled.input`
  margin-left: 0;
  width: 10px;
  height: 10px;
  margin-right: 4px;
`;

export const Form = styled.form`
  width: 100%;
  @media(max-width: 1068px){
    width:80%;
  }
`;

export const InputMask = styled.form`
  margin-bottom: 20px;
  padding: 10px 0 10px 10px;
  font-size: 14px;
  border: none;
  background-color: #f7f4ec;
  border-radius: 25px;
  border: 1px solid #000;
  width: 98%;

  ::placeholder {
    color: #000;
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    height: 60%;
  }
`;

export const Text = styled.div`
  color: #000;
  font-family: Thasadith;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media(max-width:1068px){
  color: #000;
  font-family: Thasadith;
  font-size: 20px;
  margin-bottom:10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
`;
export const Input = styled.input`
  margin-bottom: 10px;
  padding-left:10px;
  font-size: 14px;
  border: none;
  background-color: #f7f4ec;
  border-radius: 25px;
  border: 1px solid #000;
  width: 100%;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 700;
  height: 35px;
  
  line-height: normal;
  ::placeholder {
    color: #000;
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    height: 25px;

  }
`;

export const InputDate = styled.input`
  font-size: 14px;
  border: none;
  height: 35px;
  background-color: #f7f4ec;
  border-radius: 25px;
  border: 1px solid #000;
  width: 100%;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  @media (max-width: 1068px) {
    flex-wrap: nowrap;
    width: 100%;
    height: 60%;
  }
`;
export const InputCadastro = styled.input`
font-size: 14px;
background-color: #f7f4ec;
border-radius: 25px;
border: 1px solid #000;
width: 100%;
font-family: Thasadith;
font-style: normal;

  @media (max-width: 1068px) {
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const Span = styled.span`
  display: flex;
  font-size: 14px;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  justify-content: start;
`;

export const Button = styled.button`
  width: 103.2%;
  height: 35px;
  font-size: 14px;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
  font-family: Thasadith;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  background-color: #000;
  color: #fff;
  @media (max-width: 768px) {
    width: 105%;
  }
`;

export const ButtonEye = styled.span`

cursor: pointer;
color:rgb(85, 84, 82);
`;

export const ButtonEye2 = styled.span`

cursor: pointer;
color:rgb(85, 84, 82);
`;

export const ButtonEyeLogin = styled.span`
 
`;
export const SpanForgot = styled.span`
  cursor: pointer;
  font-family: Thasadith;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }
`;
export const ImgLogo = styled.div`
  display: flex;
  height: 200px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background: #f7f4ec;
  flex-shrink: 0;
  align-self: stretch;
`;
