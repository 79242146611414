import React from "react";
import { Modal, Button, Overlay } from "./styles";

const ModalAviso = ({ visible, mensagem, onClose, showOverlay }) => {
  const handleOkClick = () => {
    window.location.reload();
  };

  return (
    <>
      {visible && (
        <>
          {showOverlay && (
            <Overlay
              onClick={() => {
                onClose();
                handleOkClick();
              }}
            />
          )}
          <Modal
            visible={visible}
            onCancel={onClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Thasadith",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "20px",
                }}
              >
                Aviso
              </p>
              <p style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "16px",
                }}>{mensagem}</p>
              <Button
                key="ok"
                onClick={() => {
                  onClose();
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalAviso;
