import React, { useContext, useEffect } from "react";
import Layout from "../Layouts/Layout";
// import Follow from "components/Follow";
// import SectionSelection from "components/SectionSelection";
import {
  // Category,
  // Circle,
  InfoProduct,
  Container,
  DescriptionButton,
  DescriptionContainer,
  DescriptionButtonDisabled,
  DescriptionInfo,
  DescriptionProduct,
  ImageGrid,
  Item,
  // ItemIcon,
  Menu,
  MenuItem,
  MenuItemSize,
  MenuItemSizeNone,
  Price,
  ProductContainer,
  ProductImage,
  ProductImageGrid,
  // ProductName,
  TitleProduct,
  Section,
  SectionDetails,
  Text,
  // TextDiv,
  // Tamanho,
} from "./styles";
import { useState } from "react";
// import { BsStar, BsStarFill } from "react-icons/bs";
import ModalSize from "components/ModalSize";
import { CartContext } from "context/CartContext";
import Button from "components/Button";
import axios from "axios";
import Img from "assets/imgs/nomedia.png";
import { Link, useParams } from "react-router-dom";
import Loader from "components/Loader";
import configs from "config";
import useImageProgressive from "hooks/useImageProgressive";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const ProductDetails = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("section1");
  // const [isStarActive, setIsStarActive] = useState(false);
  const [isModalSizeOpen, setIsModalSizeOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { addToCart, cartItems } = useContext(CartContext);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const moveToSection = (id) => {
    setActiveMenuItem(id);
  };
  let isProductInCart = false;
  if (Array.isArray(cartItems)) {
    isProductInCart = cartItems.some((item) => item.id === product.id);
  }
  const params = useParams();
  const url = `${configs.api}get-product/${params.id}`;

  useEffect(() => {
    const fetchProduct = async () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      try {
        const response = await axios.get(url);
        setProduct(response.data);
        setIsLoading(false);

        const mainPhotoIndex = response.data?.images.findIndex(
          (image) => image.foto_principal
        );

        if (mainPhotoIndex !== -1) {
          setSelectedImageIndex(mainPhotoIndex);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [url]);

  const lowImage = (Array.isArray(product?.images) && product.images.length > 0)
    ? (product.images[selectedImageIndex]?.path_low || "assets/imgs/backgroundProducts.jpg")
    : null;
  const highImage = (Array.isArray(product?.images) && product.images.length > 0)
    ? (product.images[selectedImageIndex]?.path || "")
    : null;
  const [progressiveImagePath] = useImageProgressive(lowImage, highImage);


  // const handleStarClick = () => {
  //   setIsStarActive(!isStarActive);
  // };

  const toggleModalSize = () => {
    setIsModalSizeOpen(!isModalSizeOpen);
  };

  const handleModalSizeClose = () => {
    setIsModalSizeOpen(false);
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };
  const handleAddToCart = () => {
    addToCart(product);
  };

  const settings = {
    dots: window.innerWidth < 1068 ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSelectedImageIndex(next)
  };

  return (
    <Layout style={{width: "90vw", margin: "auto"}}>
      {isLoading ? (
        <div style={{ height: "700px" }}>
          <Loader />
        </div>
      ) : (
        <Container >
          {product.images ? (
            <>
              <ProductContainer>
                {product.images.length > 1 && (
                  <ProductImageGrid style={{ overflowY: "auto" }}>
                    {product.images.map((image, index) => (
                      <ImageGrid
                        key={index}
                        onClick={() => setSelectedImageIndex(index)}
                        style={{
                          borderBottom:
                            index === product.images.length - 1 ? "none" : "",
                          opacity: index !== selectedImageIndex ? "0.5" : "",
                        }}
                      >
                        <LazyLoadImage
                          src={image.path ? image.path : Img}
                          alt=""
                          width={window.innerWidth > 768 ? "100%" : "auto"}
                          height={window.innerWidth > 768 ? "250px" : "auto"}
                          style={{
                            width: "100%",
                            height: "250px",
                          }}
                          effect="blur"
                        />
                      </ImageGrid>
                    ))}
                  </ProductImageGrid>
                )}
                {product.code && (
                  <ProductImage>
                    {window.innerWidth < 1068 && (
                      <Slider {...settings}>
                        {product.images.map((image, index) => (
                          <div key={index}>
                            <img
                              src={progressiveImagePath || Img}
                              alt=""
                              effect="blur"
                              style={{  height: 'auto', margin:"auto" }}
                            />
                          </div>
                        ))}
                      </Slider>
                    )}
                    {window.innerWidth > 1068 && (
                      <img
                        src={progressiveImagePath || Img}
                        alt=""
                        effect="blur"
                      />
                    )
                    }
                  </ProductImage>

                )}
              </ProductContainer>

              <DescriptionContainer>
                <DescriptionProduct>
                  <Item>
                    {/* <ItemIcon>
                  {isStarActive ? (
                    <BsStarFill
                      size={30}
                      color="#D8DFC1"
                      onClick={handleStarClick}
                      style={{ filter: "drop-shadow(0 0 1px black)" }}
                    />
                  ) : (
                    <BsStar size={30} onClick={handleStarClick} />
                  )}
                </ItemIcon> */}
                    <MenuItemSizeNone
                      onClick={() => {
                        moveToSection("section4");
                        toggleModalSize();
                      }}
                      isActive={activeMenuItem === "section4"}
                    >
                      Guia de Tamanhos
                    </MenuItemSizeNone>
                  </Item>
                  <SectionDetails
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TitleProduct>{product.brand}</TitleProduct>
                    <Text>{product.name.replace(/-/g, "")}</Text>
                    <div>
                      <Price>{formatPrice(product.salePrice)}</Price>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "auto",
                      }}
                    >
                      <InfoProduct>
                        <b style={{ fontWeight: "800" }}>Tamanho</b>{" "}
                        {product.size}
                      </InfoProduct>
                      <InfoProduct>
                        {product.code}
                      </InfoProduct>
                      <InfoProduct>
                        <b style={{ fontWeight: "800" }}>Cor</b> {product.color}
                      </InfoProduct>

                    </div>
                  </SectionDetails>
                  <div>
                    <div style={{ marginTop: "-30px" }}>
                      {isProductInCart ? (
                        <DescriptionButtonDisabled onClick={handleAddToCart}>
                          Adicionar ao carrinho
                        </DescriptionButtonDisabled>
                      ) : (
                        <DescriptionButton onClick={handleAddToCart}>
                          Adicionar ao carrinho
                        </DescriptionButton>
                      )}
                    </div>
                  </div>
                </DescriptionProduct>
                <DescriptionInfo>
                  <div>
                    <Menu>
                      <MenuItem
                        onClick={() => moveToSection("section1")}
                        isActive={activeMenuItem === "section1"}
                      >
                        Descrição
                      </MenuItem>
                      <MenuItem
                        onClick={() => moveToSection("section2")}
                        isActive={activeMenuItem === "section2"}
                      >
                        Entrega & Pagamento
                      </MenuItem>
                      <MenuItem
                        onClick={() => moveToSection("section3")}
                        isActive={activeMenuItem === "section3"}
                      >
                        Detalhes
                      </MenuItem>
                      <MenuItemSize
                        onClick={() => {
                          moveToSection("section4");
                          toggleModalSize();
                        }}
                        isActive={activeMenuItem === "section4"}
                      >
                        Guia de Tamanhos
                      </MenuItemSize>
                    </Menu>
                  </div>
                  <div>
                    {activeMenuItem === "section1" && (
                      <Section id="section1">
                        <p>{product.description}</p>
                      </Section>
                    )}
                    {activeMenuItem === "section2" && (
                      <Section id="section2">
                        <p>Entrega</p>
                      </Section>
                    )}
                    {activeMenuItem === "section3" && (
                      <Section id="section3">
                        <p>{product.composition}</p>
                      </Section>
                    )}
                  </div>
                  {activeMenuItem === "section4" && <Section id="section4" />}
                  <Button>
                    <Link
                      to="https://wa.me/message/APZHBOCKQT5SE1"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#000" }}
                      as="div" // Renderiza o Link como uma div
                    >
                      AGENDE UM HORÁRIO
                    </Link>
                  </Button>
                </DescriptionInfo>
              </DescriptionContainer>
            </>
          ) : (
            <div style={{ width: "100%", margin: "auto" }}>
              <p>Produto não encontrado</p>
            </div>
          )}
        </Container>
      )}
      {/* <SectionSelection
        title={"VOCÊ TAMBÉM PODE GOSTAR"}
        subTitle={"Ver mais"}
      />
      <SectionSelection title={"SUGESTÕES DE CHANEL"} subTitle={"Ver tudo"} />
      <Follow /> */}

      <ModalSize isOpen={isModalSizeOpen} onClose={handleModalSizeClose} />
    </Layout>
  );
};

export default ProductDetails;
