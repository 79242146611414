import React from "react";
import { Content2 } from "./styles";

const Loader2 = () => {
  return (
    <Content2>
      <svg
        className ="logo"
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        fill="currentColor"
        viewBox="0 -3 58 80"
      >
        <path
          id="Caminho_18"
          data-name="Caminho 18"
          d="M34.638,0H27.816A27.848,27.848,0,0,0,0,27.818V47.681A27.849,27.849,0,0,0,27.816,75.5h6.821A27.849,27.849,0,0,0,62.454,47.681V27.818A27.848,27.848,0,0,0,34.638,0M2.026,26.819A25.643,25.643,0,0,1,3.5,19.191h8.891A27.3,27.3,0,0,0,2.026,26.819M30.4,19.191V51.9L17.487,29.13,3.4,56.029a25.638,25.638,0,0,1-1.391-8.348V30.174A25.179,25.179,0,0,1,20.786,19.191Zm0,36.77V73.493H27.816A25.9,25.9,0,0,1,4.382,58.477L17.559,33.319ZM27.816,2.006h6.821A25.85,25.85,0,0,1,58.157,17.185H4.3A25.844,25.844,0,0,1,27.816,2.006m6.821,71.487H32.41V55.961L45.259,33.318,58.241,58.112a25.842,25.842,0,0,1-23.6,15.381m15.346-54.3h8.982a25.688,25.688,0,0,1,1.467,7.732,27.266,27.266,0,0,0-10.449-7.732m-17.574,0h9.157A25.169,25.169,0,0,1,60.448,30.3V47.681A25.663,25.663,0,0,1,59.2,55.619L45.329,29.13,32.41,51.9Z"
        />{" "}
      </svg>
    </Content2>
  );
};

export default Loader2;
