import React, { createContext, useEffect, useState, useCallback } from "react";
import { useAuth } from "./AuthContext";
import axios from 'axios';
import configs from "config";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { id } = useAuth();
  const chaveCarrinho = `cart-guest`;

  const saveCartItemsToServer = useCallback((cartItems) => {
      axios.post(configs.api + "save-cart-products", { cartItems, id })
        .then(response => {
          console.log('Produtos do carrinho salvos no servidor com sucesso');
        })
        .catch(error => {
          console.error('Erro ao salvar produtos do carrinho no servidor:', error);
        });
  }, [id]);

  const loadUserCart = useCallback(async () => {
    if (id) {
      try {
        const response = await axios.get(configs.api + `get-cart/${id}`);
        const userCart = response.data || []; 
        const localStorageCart = JSON.parse(localStorage.getItem(chaveCarrinho)) || [];
        const uniqueProductIds = new Set();

        localStorageCart?.map((product) => uniqueProductIds.add(product.id));
        userCart.map((product) => uniqueProductIds.add(product.id));

        const mergedCart = [];

        uniqueProductIds.forEach((productId) => {
          const product = localStorageCart.find((product) => product.id === productId);
          if (product) {
            mergedCart.push(product);
          } else {
            const product = userCart.find((product) => product.id === productId);
            if (product) {
              mergedCart.push(product);
            }
          }
        });

        setCartItems(mergedCart);
        saveCartItemsToServer(mergedCart);
        localStorage.setItem(chaveCarrinho, JSON.stringify([]))
      } catch (error) {
        console.error('Erro ao carregar carrinho do usuário:', error);
      }
    }
  }, [id, saveCartItemsToServer, chaveCarrinho]);

  useEffect(() => {
    if (id) {
      loadUserCart();
    } else {
      setCartItems([]);
    }
  }, [id, loadUserCart]);

  const clearCart = () => {
    setCartItems([]);
    saveCartItemsToServer([]);
    localStorage.setItem(chaveCarrinho, JSON.stringify([]))
  };
  const addToCart = (product) => {
    const existingProductIndex = cartItems.findIndex((item) => item.id === product.id);
    const updatedCartItems = existingProductIndex !== -1
      ? [...cartItems]
      : [...cartItems, { ...product }];

    localStorage.setItem(chaveCarrinho, JSON.stringify(updatedCartItems));
    saveCartItemsToServer(updatedCartItems);
    setCartItems(updatedCartItems);

  };

  useEffect(() => {
    let cartItemsStorage = [];
    const localStorageGuest = localStorage.getItem("cart-guest");
    if(localStorageGuest){
      cartItemsStorage =  JSON.parse(localStorage.getItem("cart-guest"))
    }
    setCartItems(cartItemsStorage);
  }, []);

  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    localStorage.setItem(chaveCarrinho, JSON.stringify(updatedCartItems));
    setCartItems(updatedCartItems);
    saveCartItemsToServer(updatedCartItems);
  };

  const decreaseQuantity = (productId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
  };

  const increaseQuantity = (productId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        decreaseQuantity,
        increaseQuantity,
        clearCart 

      }}
    >
      {children}
    </CartContext.Provider>
  );
};
