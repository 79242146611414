import styled from "styled-components";

export const Header = styled.div`
  padding: 0 40px;
  border: 1px solid #000;
  border-top: 0px;
  border-left: 0;
  border-right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0 20px;
  }
`;

export const HeaderMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    padding: 0 40px;
    border: 1px solid #000;
    border-left: 0;
    border-right: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    
  }
`;

export const MenuItem = styled.li`
  padding: 10px 50px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "400" : "300")};
  color: ${({ activeColor }) => (activeColor ? "#000" : "#989898")};
  list-style: none;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0;
  }
`;

export const MenuItemPay = styled.li`
  margin-top: 10px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "bold" : "300")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  list-style: none;
  font-size: 14px;
   font-family: Thasadith;
                font-style: normal;
                line-height: normal;
                font-weight: 700; 
`;

export const Content = styled.div`
  display: flex;
  min-height: 400px;
  padding: 20px;

  @media (max-width: 1068px) {
    height: 100%;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SectionDados = styled.div`
width: 100%;
height: 100%;
padding:20px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 5%;

    p {
      text-align: center;
      font-size: 16px;
    }
  }
`;

export const SectionEndereco = styled.div`
width: 100%;
height: 100%;
padding:20px;
@media (max-width: 768px) {
  width: 90%;
  margin: 5%;

  p {
    text-align: center;
    font-size: 16px;
  }
}
`;

export const ContentAdress = styled.div`
  width: 50%;
  margin-right: 5%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentPerson = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const SectionPagamento = styled.div`
  display: flex;
  width: 60%;
  height: 100%;
  margin: 40px 60px 0 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 5% 0;
    flex-direction: column;
  }
`;

export const ContentPay = styled.div`
  width: 30%;
  margin-right: 5%;

  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`;

export const BoxPay = styled.div`
  margin-top: 50px;
  margin-bottom: 75%;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Title = styled.span`
 font-family: Thasadith;
                font-style: normal;
                line-height: normal;
                font-weight: 700; 
                
`;

export const TitleDp = styled.span`
  font-weight: 400;


  @media (max-width: 768px) {
    display: none;
  }
`;

export const TitleAdress = styled.div`
  font-weight: 400;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TitleAd = styled.div`
  font-weight: 400;
     font-size: 18px;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

`;

export const TitlePay = styled.div`
  font-weight: 400;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DivInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  height: 200px;
  gap: 10px 60px;
  margin-top: 30px;

  input {
    height: 35px;
    padding-left: 10px;

    &::placeholder {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    height: 60%;
    width: 100%;
    display:flex;
    justify-content:center;
    align-content:center;

  }
`;

export const InputMask = styled.input`
  width: "45%";

  @media (max-width: 768px) {
    height: 60%;
    width: 100%;
  }
`;

export const InputDados = styled.input`
  width: 54%;

  @media (max-width: 768px) {
    height: 60%;
    width: 100%;
  }
`;

export const DivInputsAdressOne = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  input {
    height: 40px;
    width: 76%;
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const DivInputsAdress = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  gap:4px;
  display: flex;
  align-items: center;
  input {
    height: 40px;
  }

  @media (max-width: 768px) {
    input {
      width: 100%;
    }
    margin-top:30px;
    display: flex;
    align-items: center;

  }
`;

export const BoxAdressInfo = styled.div`
  border-top: 1px solid #989898;
  border-bottom: 1px solid #989898;
  color: #989898;
  margin-top: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  p {
    font-size: 12px;
  }

  span {
    font-size: 14px;
    padding-left: 5px;
  }

  @media (max-width: 768px) {
    margin-top: 0px;

    p {
      text-align: start;
      padding-left: 10px;
    }
  }
`;

export const SectionAdress = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputRadio = styled.input`
  margin-left: 0;
  width: 16px;
  height: 16px;
`;

export const Div2 = styled.div`
  width: 30%;
  margin-top: 40px;
  margin-right: 40px;
  overflow-y: auto;

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DivMobile = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    overflow-y: auto;
    position: absolute;
    bottom:0;
    background-color:#fff;

    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
`;

export const BoxDiv2 = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid;
  border-bottom: 1px solid;
`;

export const SectionProduct = styled.div`
  display: flex;
  /* position: relative; */
  padding: 20px 0;
  border-bottom: 2px solid #000;
`;

export const ProductImage = styled.div`
  display: flex;
  width: 40%;
  img {
    width: 130px;
    height: 150px;
  }
`;

export const ProductDetails = styled.div`
  p {
    margin: 0 20px 5px 20px;
    font-size: 14px;
  }
`;

export const ProductPrice = styled.div`
  p {
    margin-top: 50px;
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 14px;
  }
`;

export const RemoveButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin-right: 30px;
`;

export const SectionEnd = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #000;
  padding: 20px 0;
`;

export const TitleEnd = styled.h2`
  font-size: 14px;
  font-weight: normal;
`;

export const BackCart = styled.div`
  font-size: 14px;
  font-weight: normal;
`;

export const SectionSub = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  width: 100%;
  margin-top: 40px;
  display: flex;
  padding: 15px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  
  @media (max-width: 1068px) {
    font-size: 14px;
    width: 100%;
    height:45px;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const Button2 = styled.button`
  width: 130px;
  height: 45px;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  border: 1px solid rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 50px;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font: normal normal normal 12px ${(props) => props.theme.font.family};
  @media (max-width:1068px){
    width:350px;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;

export const ButtonMobile = styled.button`
height: 45px;
width:100%;
font-family: Thasadith;
font-style: normal;
font-weight: 800;
line-height: normal;
display: flex;
margin-top:10px;
border: 1px solid rgb(0, 0, 0);
cursor: pointer;
border-radius: 50px;
justify-content: center;
align-items: center;
color: rgb(255, 255, 255);
  @media (max-width: 768px) {
    height: 45px;
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
  }
`;

export const ButtonEntrega = styled.button`
font-family: Thasadith;
font-style: normal;
font-weight: 800;
line-height: normal;
display: flex;
cursor: pointer;
border-radius: 50px;
padding:10px;
border:1px solid gray;
justify-content: center;
align-items: center;
color: rgb(255, 255, 255);
background-color:gray;
  @media (max-width: 768px) {
    font-family: Thasadith;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    cursor: pointer;
    border-radius: 50px;
    padding:10px;
    height:45px;
    border:1px solid gray;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color:gray;
  }
`;

export const SectionVale = styled.div`
  width: 60%;
  margin-top: 30px;

  input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
  }

  @media (max-width: 768px) {
    display: flex;
    width: 100%;

    input {
      width: 90%;
      margin: 2% 4%;
      font-size: 16px;
    }
  }
`;

export const SectionCartao = styled.div`
  width: 100%;
  margin-top: 30px;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  label {
    font-size: 14px;
  }

  input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  select {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  p,
  span {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
  }
`;

export const SectionBoleto = styled.div`
  width: 70%;

  span {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    p,
    span {
      width: 90%;
      margin: 4%;
      font-size: 16px;
    }
  }
`;

export const BoxPix = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 100px;
    margin: 10px 0; /* Espaçamento superior e inferior entre o textarea e a imagem */
  }

  @media (max-width: 768px) {
    display: flex;

    img {
      width: 90%;
      margin: 4%;
    }
  }
`;

export const RenderContentPay = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
