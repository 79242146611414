import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1200px;
  height: auto;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 999;
  box-shadow: 14px 23px 15px -3px rgba(0, 0, 0, 0.4);
  border: 1px solid #000;

  @media (max-width: 768px) {
  margin:auto;
  width: 80%;
  height: auto;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 999;
  box-shadow: 14px 23px 15px -3px rgba(0, 0, 0, 0.4);
  border: 1px solid #000;
  }
`;

export const SubContainer = styled.div`
  position: relative;
`;

export const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 26px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10% 0;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 1% 0 5% 0;
  }
`;

export const DivTitle = styled.div`
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ContentTable = styled.div`
  width: 90%;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const TableHeader = styled.th`
  font-weight: 400;
  padding: 10px;
  border: 1px solid #000;
`;

export const TableCell = styled.td`
  width: calc(100% / 8);
  padding: 10px;
  border: 1px solid #000;

  &:first-child {
    font-weight: 400;
  }
`;
