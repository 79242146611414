import React from "react";
import {
  ButtonContainer,
  Content,
  ContinueButton,
  Modal,
  Text,
  ImgLogo,
  ModalContent
} from "./styles";
import { Link } from "react-router-dom";
import logo_footer from "assets/imgs/logo-footer.png";
import logo from "assets/imgs/logo.png";
import { CloseButton } from "components/ModalMobile/styles";
const Address = ({ isOpen, onClose }) => {
  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <CloseButton onClick={handleClose}>X</CloseButton>
        <ImgLogo>
          <img width={50} src={logo_footer} alt="Logo Takeme"></img>
          <img width={window.innerWidth > 768 ? 250 : 200} src={logo} alt="Takeme"></img>
        </ImgLogo>
        <Content>
          <ModalContent>
            <Text>Onde encontrar</Text>
            <p style={{ fontSize: "18px", fontWeight: "500" }}>Shopping Cidade Jardim</p>
            <p style={{ fontSize: "14px", fontWeight: "100" }}>Av Magalhães de Castro, 12.000, no 3⁰ andar, ao lado da World Wine - porta madeira. <br></br>Horário - Segunda a Sexta - 09 às 18h</p>

            <p style={{ fontSize: "18px", fontWeight: "500" }}>CJSHOPS (EM BREVE)</p>

            <p style={{ fontSize: "14px", fontWeight: "100" }}>Rua Haddock Lobo, 1626, no 2⁰ piso - Jardins</p>
            <ButtonContainer>
              <Link
                to="https://wa.me/message/APZHBOCKQT5SE1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#fff", width:"100%"}}
                as="div"
              >
                <ContinueButton>
                  AGENDE UM HORÁRIO
                </ContinueButton>
              </Link>

            </ButtonContainer >
          </ModalContent>

        </Content>
      </Modal>

    </>


  );
};

export default Address;
