import React from "react";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import CardProduct from "components/CardProduct";
import Banner from "../../components/BannerHeader";


const Shop = () => {
  return (
    <Layout colorMenu={"#fff"}>
      <Banner imgBanner="" title="Shop" description=""/>
      <CardProduct filters="shop"/>
      <Follow />
    </Layout>
  );
};

export default Shop;
