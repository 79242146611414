import { Link } from "react-router-dom";
import {
  Close,
  Container,
  FlexDiv,
  FlexDiv2,
  SubContainer,
  TextMenu,
  Title,
} from "./styles";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const CookieModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleModalClose = (event) => {
    if(event === true){
      setIsModalOpen(false);
      Cookies.set("cookieConsent", "true", { expires: 7 });
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (cookieConsent === "true") {
      setIsModalOpen(false);
    }
  }, []);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Container className={props.className}>
      <SubContainer>
        <Title>
          <span>Cookies</span>
        </Title>
        <Close onClick={() => handleModalClose(false)}>X</Close>
        <FlexDiv>
          <TextMenu>
            <span>
              <Link to="/termos#section3">Política de Privacidade</Link>
            </span>
          </TextMenu>
          <FlexDiv2>
            <TextMenu>
              <span onClick={() => handleModalClose(false)}>RECUSAR</span>
            </TextMenu>
            <TextMenu>
              <span onClick={() => handleModalClose(true)}>ACEITAR</span>
            </TextMenu>
          </FlexDiv2>
        </FlexDiv>
      </SubContainer>
    </Container>
  );
};

export default CookieModal;
