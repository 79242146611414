import React, { useState } from "react";
import { Button, Title, CenteredDiv } from "./styles";
import { useNavigate } from "react-router-dom";


const PagamentoBoleto = ({ dados }) => {
  const navigate = useNavigate();
  let qrCode = null;
  let text = null;
  let id = null;

  if (dados) {
    id = dados.qr_codes[0].id;
    qrCode = dados.qr_codes[0].links[0];
    text = dados.qr_codes[0].text;
  }

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);

        // Reset the "copied" state after a few seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Erro ao copiar para a área de transferência:", error);
      });
  };

  return (
    <CenteredDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Title>Seu pedido foi realizado com sucesso</Title>
        <p style={{ fontSize: "14px" }}>
          Utilize o QR code abaixo para finalizar o pagamento
        </p>
        <Button onClick={copyToClipboard}>Pagar com PIX cópia e cola</Button>

        {isCopied && (
          <div style={{ marginTop: "10px", color: "green" }}>
            Código copiado com sucesso!
          </div>
        )}
        <div
          style={{
            display: "flex",
            padding: "50px",
            marginTop: "20px",
            flexDirection: "column",
            marginBottom: "30px",
            borderRadius: "10px",
            border: "1px solid var(--Marrom, #c0b6ad)",
          }}
        >
          <p style={{fontSize:"14px", textAlign:"center"}}>{id}</p>
          <img style={{margin:"auto"}} width={"300px"} src={qrCode.href} alt="QRCODE" />
          <p style={{fontSize:"14px"}}>
           1. Abra o app do seu banco ou instituição financeira e entre no
            ambiente Pix
          </p>
          <p style={{fontSize:"14px"}}>2. Escolha a opção Pagar com QR code e escaneie o código ao lado</p>
          <p style={{fontSize:"14px"}}>3. Confirme as informações e finalize o pagamento</p>
          <Button style={{margin:"auto", marginTop:"10px"}}  onClick={() => navigate("/conta?pedido=true")}>Ir para "Meus Pedidos"</Button>

        </div>
      </div>
    </CenteredDiv>
  );
};

export default PagamentoBoleto;
