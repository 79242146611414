import styled from "styled-components";

export const Container = styled.div`
  width: 570px;
  height: 112px;
  background-color: ${({ theme }) => theme.color.primary};
  border: solid 1px;
  position: fixed;
  right: 2%;
  bottom: 0;
  z-index: 99;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 95%;
    height: 12%;
    right: 2%;
    bottom: -3%;
  }
`;

export const SubContainer = styled.div`
  position: relative;
`;

export const Close = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  padding-top: 10px;
  padding-left: 5%;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
`;

export const FlexDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35%;

  @media (max-width: 768px) {
    width: 30%;
  }

  @media (max-width: 500px) {
    width: 40%;
  }
`;

export const TextMenu = styled.div`
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 7px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  a {
    color: #000;
  }
`;
