import React, { useEffect, useRef, useState } from "react";
import {
  FooterContainer,
  ItemFooter,
  ConteudoFooter,
  HeaderTitle,
  HeaderSpan,
  HeaderLocal,
  LogoFooter,
  ItemFooterMobile,
  ItemFooterDiv,
  ModalAddressBarContent,
  ModalAddressBarWrapper,
} from "./styles";
import Logo from "../../../assets/imgs/logo-footer.png";
import { FaMapMarkerAlt } from "react-icons/fa";
import Address from "../../../components/Address";
const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const modalAddressRef = useRef()
  const [isModalAddressOpen, setIsModalAddressOpen] = useState(false);
  const handleModalAddressClose = () => {
    setIsModalAddressOpen(false);
  };

  const toggleModalAddress = () => {
    setIsModalAddressOpen(!isModalAddressOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {

      if (
        modalAddressRef.current &&
        !modalAddressRef.current.contains(event.target)
      ) {
        handleModalAddressClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalAddressRef]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        {windowWidth > 768 ? (
          <>
            <ItemFooter>
              <ConteudoFooter>
                <LogoFooter>
                  <img src={Logo} alt="Logo Take Me" />
                </LogoFooter>
                <HeaderLocal style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                  <HeaderSpan>
                    <p style={{ fontWeight: "500" }}>© 2024 Take Me. All Right Reserved.</p>
                  </HeaderSpan>
                </HeaderLocal>
              </ConteudoFooter>
            </ItemFooter>
            <ItemFooterMobile>
              <ConteudoFooter>
                {/* <HeaderTitle>Newsletter</HeaderTitle> */}
                <HeaderTitle>
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.instagram.com/takeme.online/"
                  >
                    Instagram
                  </a>
                </HeaderTitle>
                {/* <HeaderTitle>Youtube</HeaderTitle>
                <HeaderTitle>Contato</HeaderTitle> */}
              </ConteudoFooter>
            </ItemFooterMobile>
            <ItemFooter borderRight>
              <ConteudoFooter>
                <HeaderTitle>
                  <a href="/">Take Me Vintage</a>
                </HeaderTitle>
                <HeaderTitle><a href="/around">Take Me Around</a></HeaderTitle>
                <HeaderTitle><a href="/selection">Take Me Selection</a></HeaderTitle>
                <HeaderTitle>
                  <a href="/sellwithus">Sell With Us</a>
                </HeaderTitle>
                <HeaderTitle>
                  <a href="/giveme">Give Me</a>
                </HeaderTitle>
              </ConteudoFooter>
            </ItemFooter>
            <ItemFooter borderRight>
              <ConteudoFooter>
                <HeaderTitle>
                  <a href="/termos#section3">Política de Privacidade</a>
                </HeaderTitle>
                {/* <HeaderTitle>
            <a href="/termos#section2">Termos e Condições</a>
          </HeaderTitle>
          <HeaderTitle>
            <a href="/termos#section1">Retornos e Política de Devolução</a>
          </HeaderTitle>
          <HeaderTitle>
            <a href="/termos#section4">Entregas e Retiradas</a>
          </HeaderTitle>
          <HeaderTitle>
            <a href="/termos#section5">Metódos de Pagamento</a>
          </HeaderTitle> */}
                {/* Add more links as needed */}
                <HeaderTitle>
                  <a href="/faq">FAQ</a>
                </HeaderTitle>
              </ConteudoFooter>
            </ItemFooter>
            <ItemFooterDiv>
              <ConteudoFooter>
                {/* <HeaderTitle>Newsletter</HeaderTitle> */}
                <HeaderTitle><a href="/about">About</a></HeaderTitle>
                <HeaderTitle>
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.instagram.com/takeme.online/"
                  >
                    Instagram
                  </a>
                </HeaderTitle>
                <HeaderTitle style={{cursor:"pointer"}} onClick={toggleModalAddress}>
                  Onde Encontrar <FaMapMarkerAlt />
                </HeaderTitle>
                {/* <HeaderTitle>Youtube</HeaderTitle>
                <HeaderTitle>Contato</HeaderTitle> */}
              </ConteudoFooter>
            </ItemFooterDiv>

          </>
        ) : (
          <>
            <ItemFooter borderRight>
              <ConteudoFooter>
                <HeaderTitle>
                  <a href="/">Take Me Vintage</a>
                </HeaderTitle>
                <HeaderTitle><a href="/around">Take Me Around</a></HeaderTitle>
                <HeaderTitle><a href="/selection">Take Me Selection</a></HeaderTitle>
                <HeaderTitle>
                  <a href="/sellwithus">Sell With Us</a>
                </HeaderTitle>
                <HeaderTitle>
                  <a href="/giveme">Give Me</a>
                </HeaderTitle>
                <HeaderTitle  style={{cursor:"pointer"}} onClick={toggleModalAddress}>
                  Onde Encontrar <FaMapMarkerAlt />
                </HeaderTitle>
              </ConteudoFooter>
            </ItemFooter>
            <ItemFooterMobile style={{ border: "none" }}>
              <ConteudoFooter>
                {/* <HeaderTitle>Newsletter</HeaderTitle> */}
                <HeaderTitle><a style={{ textDecoration: "none" }} href="/about">About</a></HeaderTitle>
                <HeaderTitle>
                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.instagram.com/takeme.online/"
                  >
                    Instagram
                  </a>
                </HeaderTitle>
                {/* <HeaderTitle>Youtube</HeaderTitle>
                <HeaderTitle>Contato</HeaderTitle> */}
                <HeaderTitle>
                  <a href="/termos#section3">Política de Privacidade</a>
                </HeaderTitle>
                <HeaderTitle>
                  <a href="/faq">FAQ</a>
                </HeaderTitle>
                
              </ConteudoFooter>
            </ItemFooterMobile>
          </>
        )}

      </FooterContainer>
      {/* <HeaderLocal style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <HeaderSpan>
          <p style={{ fontWeight: "700" }}>© 2024 Take Me. All Right Reserved.</p>
      </HeaderSpan>
          <span>Shopping Cidade Jardim (Av Magalhães de Castro, 12.000), no 3⁰ andar, entre a World Wine e a Casa Bossa Horário - Segunda a Sexta - 09 às 18h Agende um horário - (11)99990-0100 CJSHOPS (Rua Haddock Lobo, 1626- Jardins) - LOJA (EM BREVE)</span>
      </HeaderLocal> */}
      {windowWidth < 768 && (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            textAlign: "center",
            backgroundColor: "#d8dfc1",
            width: "100%",
            height: "40px",
          }}
        >
          <HeaderTitle>
            <p style={{ fontWeight: "700" }}>© 2024 Take Me. All Right Reserved.</p>
          </HeaderTitle>
        </div>
      )}
      {isModalAddressOpen && (
        <ModalAddressBarWrapper isOpen={isModalAddressOpen}>
          <ModalAddressBarContent isOpen={isModalAddressOpen} ref={modalAddressRef}>
            <Address
              onLogin={handleModalAddressClose}
              onClose={toggleModalAddress}
            />
          </ModalAddressBarContent>
        </ModalAddressBarWrapper>
      )}
    </div>


  );
};

export default Footer;
