import React, { useContext, useState, useEffect } from "react";
import Layout from "../Layouts/Layout";
import {
  CartContainer,
  CartTable,
  Header,
  HeaderTitle,
  TotalRow,
  ButtonContainer,
  ContinueButton,
  CheckoutButton,
  Table,
  TableRow,
  TableSeparatorUp,
  TableSeparatorDown,
  Td,
  CartEmpty,
  TitleEmpty,
  BoxEmpty,
  RemoveItem,
  DivTotal,
  Box,
  RemoveButton,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { CartContext } from "context/CartContext";
import NoImage from "assets/imgs/nomedia.png";
import { BsTrash } from "react-icons/bs";

const Cart = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  const { cartItems, removeFromCart } =
    useContext(CartContext);

  const formattedCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const isCartEmpty = cartItems.length === 0;

  useEffect(() => {
    const calculateTotal = () => {
      let newTotal = 0;
      cartItems.forEach((item) => {
        const salePriceAsNumber = parseFloat(item.salePrice);
        if (!isNaN(salePriceAsNumber)) {
          newTotal += salePriceAsNumber;
        }
      });
      setTotal(newTotal);
    };

    calculateTotal();

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [cartItems]);

  const renderTable = () => {
    return (
      <CartTable>
        <Table>
          <thead>
            <TableRow>
              <TableSeparatorUp />
              <th>ITEM</th>
              <th>COR</th>
              <th>TAMANHO</th>
              <th>VALOR</th>
              <th></th>
              <th></th>
              <TableSeparatorDown />
            </TableRow>
          </thead>
          <tbody>
            {cartItems.map((item) => (
              <TableRow>
                <Td>
                  <img src={item.images[0]?.path || NoImage} alt="" />
                </Td>
                <Td>{item.name.replace(/-/g, "")}</Td>
                <Td>{item.color}</Td>
                <Td>{item.size}</Td>
                <Td>{formatCurrency(item.salePrice)}</Td>
                <Td></Td>
                <Td>
                  <RemoveItem onClick={() => handleRemoveFromCart(item.id)}>
                  <BsTrash></BsTrash>
                  </RemoveItem>
                </Td>
                <TableSeparatorDown />
              </TableRow>
            ))}
          </tbody>
          <tfoot>
            {cartItems.length > 0 && (
              <TotalRow>
                <th colSpan="5">TOTAL</th>
                <th style={{ paddingLeft: "10px" }}>
                  {formattedCurrency(total)}
                </th>
                <TableSeparatorDown />
              </TotalRow>
            )}
          </tfoot>
        </Table>
      </CartTable>
    );
  };

  const renderMobileView = () => {
    return (
      <div style={{
        borderBottom: "1px solid #000",
        width:"80%"}}>
        {cartItems.map((item) => (
          <div
            key={item.id}
            style={{
              marginTop: "20px",
              display: "flex",
              borderBottom: "1px solid #000",
              width:"100%",
            }}
          >
            <div>
              <img
                src={item.images[0]?.path || NoImage}
                height= {windowWidth < 768 ? "100px" : "100px"}
                alt=""
              />
            </div>
            <Box>
              <div>
                <span>{item.name.replace(/-/g, "")}</span>
                <span>Tamanho: {item.size}</span>
                <span>Cor: {item.color}</span>
              </div>
              <div>
                <span>R$ {item.salePrice}</span>
              </div>
            </Box>
            <RemoveButton onClick={() => handleRemoveFromCart(item.id)}>
            <BsTrash></BsTrash>
            </RemoveButton>
          </div>
        ))}
        {cartItems.length > 0 && (
          <DivTotal>
            <span>TOTAL</span>
            <span style={{ marginLeft: "40%" }}>
              {formattedCurrency(total)}
            </span>
          </DivTotal>
        )}
      </div>
    );
  };

  return (
    <Layout>
      <Header>
        <HeaderTitle>MEU CARRINHO</HeaderTitle>
      </Header>
      {isCartEmpty ? (
        <CartEmpty>
          <BoxEmpty>
            <TitleEmpty>Nenhum produto no carrinho.</TitleEmpty>
          </BoxEmpty>
        </CartEmpty>
      ) : (
        <CartContainer>
          {isMobileView ? renderMobileView() : renderTable()}
          <ButtonContainer>
            <ContinueButton onClick={() => navigate("/novidades")}>
              Continuar comprando
            </ContinueButton>
            <CheckoutButton onClick={() => navigate("/checkout")}>
              Finalizar compra
            </CheckoutButton>
          </ButtonContainer>
        </CartContainer>
      )}
    </Layout>
  );
};

export default Cart;
