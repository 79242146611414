import styled from "styled-components";

export const Header = styled.div`
  border: 1px solid #000000;
  border-top: 0px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
  border-left: none;
`;

export const HeaderTitle = styled.div`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const CartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
`;

export const CartTable = styled.div`
  width: 80%;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 5%;

  th:nth-child(1),
  td:nth-child(1) {
    width: 15%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 20%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 15%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 15%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:nth-child(7),
  td:nth-child(7) {
    width: 20%;
  }

  th {
    padding: 10px;
    text-align: start;
  }
`;

export const Td = styled.td`
  padding: 10px;

  img {
    height: 150px;
  }

  @media(max-width: 1068px){
    img {
      height: 150px;
    }
  }
`;

export const TableRow = styled.tr`
  position: relative;

  th {
    font-size: 14px;
    font-weight: 400;
  }

  td {
    font-size: 14px;
  }
`;

export const TableSeparatorUp = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #000;
`;

export const TableSeparatorDown = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: #000;
`;

export const TotalRow = styled.tr`
  position: relative;

  th {
    font-weight: normal;
    font-size: 14px;
    padding: 12px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 20%;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 6%;
  }
`;

export const ContinueButton = styled.button`
  width: 40%;
  padding: 30px 10%;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  background-color: #fff;
  font-size: 16px;
  color : #000;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    color : #000;
    background-color: #fff;
  }
`;

export const CheckoutButton = styled.button`
  width: 40%;
  padding: 30px 10%;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    margin-top: 5%;
  }
`;

export const RemoveItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
  font-size:18px;
`;

export const CartEmpty = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const BoxEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 3%;

  @media (max-width: 1024px) {
    margin-top: 7%;
  }

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`;

export const TitleEmpty = styled.p`
  font: normal normal normal 20px ${(props) => props.theme.fontTitle.family};
  border: 1px solid #989898;
  color: #989898;
  padding: 20px 180px;

  @media (max-width: 1024px) {
    font-size: 16px;
    padding: 20px 100px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 20px 100px;
  }
`;

export const ButtonQuantity = styled.button`
  background: none;
  border: none;
  margin: 10px;
  cursor: pointer;
  font-size: 20px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 0px 10px;

  div {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  span {
    padding-bottom: 5px;
    font-size: 14px;
  }
`;

export const DivTotal = styled.div`
  display: flex;
  height:30px;
  margin-top:10px;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  width:100%;
`;

export const RemoveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;
