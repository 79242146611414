import styled from "styled-components";

export const Content = styled.div`
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height:100vh;
  @media (max-width: 1000px) {
      height:100%;
  }

  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
`;

export const Image = styled.div`
  width: 50%;

  ${({ borderRight }) => (borderRight ? "border-right: 1px solid #000" : "")}

   @media (max-width: 1000px) {
    width: 100%;
    margin:10px;
  }

`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: rgb(28, 27, 45);
  position: relative;

  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
  }
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: 60%;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: 1000px) {
    width: 100vw;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 40px 0;
  color:#fff;
  @media (max-width: 1000px) {
    padding: 20px 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 60px;
  text-align: center;
  color:#fff;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

export const Text = styled.p`
  text-align: center;
  width: 75%;
  margin: 0 auto;
  padding: 40px 0;
  font-size: 24px;
  color:#fff;
  @media (max-width: 1000px) {
    font-size: 18px;
    padding: 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 22%;
  padding-top: 6%;
  text-align: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const NumberCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #f3d5d1;
  font-size: 18px;
  margin-bottom: 10px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  font-size: 24px;
  margin: 20px 0;
  font-weight: normal;

  @media (max-width: 1000px) {
    font-size: 22px;
  }
`;

export const Description = styled.p`
  font-size: 24px;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

export const IconCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 40px;
  padding-top: 30px;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-top: 10%;
  }
`;

export const IconCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 750px;
  height: 450px;
  padding: 20px;
  background-color: #f3d5d1;
  text-align: center;
  border-radius: 20px;

  @media (max-width: 1000px) {
    padding: 20px 0;
    margin: 0 20px;
    height: 100%;
  }
`;

export const Icon = styled.img`
  width: 30%;
  height: 35%;
  margin: 20px 0;
`;

export const IconTitle = styled.p`
  font-size: 18px;
`;

export const ButtonDiv = styled.div`
padding: 80px;

@media (max-width: 1000px) {
  padding: 30px;
  width:80%;
}
`;

export const ButtonSt = styled.div`
  padding: 30px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 14px;
    display: flex;
  }
`;
