import styled from "styled-components";
import home_img from "../../assets/imgs/chapeu_vestido_mobile.png";

export const BackgroundImage = styled.div`
  height: 100vh;
  background-image: url(${(props) => props.image});
  background-size: cover;
  position: relative;
  border-bottom: 1px solid;
  background-position: center; 

  @media (max-width: 1068px) {
    background-image: url(${home_img});
    height: 90vh;
    background-size: cover;
    background-position: center; 
  }
`;

export const TitleDiv = styled.div`
  position: absolute;
  bottom: 20px;
  color: #fff;
  margin-left: 30px;

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    margin-left: 0;
    bottom: 20%;
  }
`;

export const Title = styled.span`
  color: #fff;
  font: normal normal normal 36px Thasadith;

  @media (max-width: 768px) {
    font: normal normal normal 30px Thasadith;
  }
`;

export const ShopNow = styled.span`
  color: #fff;
  cursor: pointer;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
