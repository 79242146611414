import styled from "styled-components";

export const ButtonSt = styled.button`
  width: 20%;
  background: rgb(247, 244, 236);
  font-size: 18px;
  height: 50px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 25px;
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 9;

  &:hover {
    background-color: #d8dfc1;
  }

  @media (max-width: 1024px) {
    width: 30%;
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: 50%;
    height: 40px;
    font-size: 14px;
  }
`;
