import styled from "styled-components";
export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f4ec;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
`;

export const ModalContent = styled.div`
  width: 80%;
  padding-top: 50px;

  section {
    padding: 20px 20px 20px 0;
    cursor: pointer;

    div {
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #ccc;
`;

export const menuItem = {
  padding: "10px 20px",
  cursor: "pointer",
  borderBottom: "1px solid #ddd",
};

export const fontMenuDropDown = {
  fontSize: "14px",
};

export const scrollContainer = {
  maxHeight: "300px",
  overflowY: "auto",
  backgroundColor: "rgb(234, 229, 211)",
};


export const MenuDiv1 = styled.div`
  z-index: 10;
  position: relative;

  ${({ open }) => (!open ? "display: none" : "")}
`;

export const MenuDiv2 = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height:600px;

`;

export const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  gap: 16px;
  margin: 2% 2%;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.color.primary};
  color: #000;
  font-size: 20px;
  padding: 4px 8px;
  border: none;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : "normal")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  text-underline-offset: 7px;
  margin-right: 20px;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight:500;
`;

export const Content = styled.div`
  display: flex;
  width: 77.3%;
`;

export const ListShop = styled.div`
   flex: 1;
  background-color: rgb(247, 242, 231);
  width: 100%;
  border: none;

  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
`;

export const ListBrands = styled.div`
  flex: 1;
  background-color: rgb(247, 242, 231);
  width: 100%;
  border: none;

  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
`;

export const Background = styled.div`
  flex: 1;
  background-color: #fff;
  background-size: cover;
  border: 1px solid #000000;
  border-right: none;
  border-top: none;

`;

export const Imagem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemList = styled.div`
  font: normal normal normal 20px ${(props) => props.theme.font.family};
  letter-spacing: 0px;
  font-weight: ${({ isBold }) => (isBold ? isBold : 300)};
  cursor: pointer;
  background-color:rgb(247, 242, 231);
`;

