import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Header = styled.div`
  flex: 0 1 auto;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  position: relative;
  display:flex;
  align-items:center;
  justify-content:center;
`;
export const FlexMenu = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
`;

export const ItemMenu = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const ItemMenuLogo = styled.img`
  cursor: pointer;
  padding-left:30px;
  padding-right:30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ItemMenuNav = styled.div`
  padding:25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  img {
    cursor: pointer;
  }

   @media (max-width: 1024px) {
    padding:12px;
  }
  
`;

export const ItemSubMenu = styled.div`
  display: flex;
  padding-right: 13.5px;
  padding-left: 13.5px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ItemSubMenuBag = styled.div`
  display: flex;
  padding-right: 13.5px;
  padding-left: 13.5px;
  cursor: pointer;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ItemSubMenuSearch = styled.div`
  display: flex;
  padding-right: 13.5px;
  padding-left: 13.5px;
  cursor: pointer;
  align-items: center;
  justify-content:center;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ItemSubMenuLogin = styled.div`
display: flex;
padding-right: 13.5px;
padding-left: 13.5px;
cursor: pointer;

@media (max-width: 1024px) {
  display: block;
  font-size: 0px;
}

@media (max-width: 768px) {
  display: block;
}
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;

  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

export const ModalLoginBar = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalLoginBarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalLoginBarContent = styled.div`
  position: absolute;
  right: 0;
  z-index: 9999;
  height: 100%;
  width: 33%;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: translateX(${({ isOpen }) => (isOpen ? "0%" : "100%")});
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1);
`;


export const ModalCartBarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalCartBarContent = styled.div`
  position: absolute;
  right: 0;
  z-index: 9999;
  height: 100%;
  width: 33%;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transform: translateX(${({ isOpen }) => (isOpen ? "0%" : "100%")});
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1);
`;
export const ModalMobileBar = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
`;

export const CartItemCount = styled.div`
  display: flex;
  position: absolute;
  right: -2px;
  bottom: 8px;

  span {
    font-size: 14px;
  }
`;

export const MenuResponsive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 100%;
  padding: 20px;
`;

export const ButtonSpanHeader = styled.span`
  font-weight:700;
  text-decoration:none;
  color: #000;
  @media (max-width: 768px) {
    font-size: 10px;
    text-decoration:none;
    color: #000;
  }
`;