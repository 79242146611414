import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 40px;
  background-color: #f7f4ec;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 0px;
    gap: 0px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 35px 50px;

  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }
`;

export const MenuItem = styled.a`
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    padding: 5px 20px;
    border-top: 1px solid #000;
  }
`;

export const Section = styled.div`
  margin-bottom: 20px;
  width: 85%;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: normal;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
