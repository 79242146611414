import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  background: ${({ active, theme }) =>
      active
        ? theme.color.secondary
        : (props) => (props.color ? props.color : props.theme.color.primary)}
    0% 0% no-repeat padding-box;
  opacity: 1;
  height: 50px;
  font-size:18px;
  font-family: 'Thasadith', sans-serif;
  letter-spacing: 0px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.color.secondary};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    height: 35px;
  }

  border: 1px solid #fff;
  border-top: none;
`;

export const ButtonSpan = styled.span`
  font-weight:700;
  text-decoration:none;
  color: #000;
  @media (max-width: 768px) {
    font-size: 11px;
    text-decoration:none;
    color: #000;
  }
`;
