import React, { useState, useEffect } from "react";
import {
  CheckboxInput,
  CheckboxLabel,
  Icon,
  Categorias,
  SubmenuScrollable,
  FilterContainer,
  ListaFiltro,
  RangeInput,
  ButtonFilter,
  ButtonPrice
} from "./styles";
import {
  BsArrowDown,
  BsArrowUp,
  BsChevronDown,
  BsChevronRight,
  BsChevronUp,
} from "react-icons/bs";
import axios from "axios";
import configs from "config";
import { SearchInputFilter } from "components/Search/styles";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useSearchParams } from "react-router-dom";
const Filter = ({ onFilterChange, searchFilter }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    categoria: [],
    tamanho: [],
    cor: [],
    ocasiao: [],
    preco: { min: null, max: null },
    marca: [],
  });
  const [sizes, setSize] = useState([]);
  const [colors, setColors] = useState([]);
  const [groups, setGroup] = useState([]);
  const [brands, setBrand] = useState([]);
  const [ocasioes, setOcasiao] = useState([]);
  const [prevSearchFilter, setPrevSearchFilter] = useState([]);
  const [searchParams] = useSearchParams();
  const [priceRange, setPriceRange] = useState({ min: null, max: null });
  const [searchTerms, setSearchTerms] = useState({
    categoria: "",
    tamanho: "",
    cor: "",
    ocasiao: "",
    marca: "",
  });

  const [opcaoSelecionada, setOpcaoSelecionada] = useState("");
  const handleFilterChange = (updatedFilters) => {
    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);

  };
  const [selectedCategory, setSelectedCategory] = useState(null);

  var busca = searchParams.get("busca");
  if (busca) {
    busca = true
  } else {
    busca = false
  }


  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSearch = (event, filterName) => {
    setSearchTerms((prevSearchTerms) => ({
      ...prevSearchTerms,
      [filterName]: event.target.value,
    }));
  };

  const handleCheckboxChange = (event, filterName) => {
    const { value, checked } = event.target;
    const updatedFilters = { ...selectedFilters };
    if (checked) {
      updatedFilters[filterName] = [...updatedFilters[filterName], value];
    } else {
      updatedFilters[filterName] = updatedFilters[filterName].filter(
        (filter) => filter !== value
      );
    }
    handleFilterChange(updatedFilters);
  };

  const filterItems = (items, filterName) => {
    const searchTerm = searchTerms[filterName].toLowerCase();
    return items.filter((item) => item.toLowerCase().includes(searchTerm));
  };

  const handlePrecoChange = (event) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.preco = event;
    handleFilterChange(updatedFilters);
  };

  const handleOrdenacaoChange = (opcao) => {
    const updatedFilters = { ...selectedFilters };
    updatedFilters.orderBy = opcao;
    setOpcaoSelecionada(opcao);
    handleFilterChange(updatedFilters);
  };

  function removeAccents(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  useEffect(() => {
    const fetchInitialFilters = async () => {
      try {
        const response = await axios.get(configs.api + "get-allproducts?takeme=true");
        setColors(response.data.color);
        setGroup(response.data.group);
        setSize(response.data.size);
        setOcasiao(response.data.ocasiao ?? []);
        setBrand(response.data.brand);
      } catch (error) {
        console.error("Error fetching initial filters:", error);
      }
    };

    fetchInitialFilters();
  }, []);

  useEffect(() => {
    const searchFilterWithoutAccents = removeAccents(searchFilter);
    const foundSize = sizes.find((size) =>
      removeAccents(size.description.toLowerCase()).includes(
        searchFilterWithoutAccents.toLowerCase()
      )
    );

    const foundColor = colors.find((color) =>
      removeAccents(color.description.toLowerCase()).includes(
        searchFilterWithoutAccents.toLowerCase()
      )
    );

    const foundGroup = groups.find((group) =>
      removeAccents(group.description.toLowerCase()).includes(
        searchFilterWithoutAccents.toLowerCase()
      )
    );

    const foundBrand = brands.find((brand) =>
      removeAccents(brand.description.toLowerCase()).includes(
        searchFilterWithoutAccents.toLowerCase()
      )
    );

    const foundOcasiao = ocasioes.find((ocasiao) =>
      removeAccents(ocasiao.description.toLowerCase()).includes(
        searchFilterWithoutAccents.toLowerCase()
      )
    );

    setSelectedFilters((prevFilters) => {
      let updatedFilters = "";
      if (busca === true) {
        updatedFilters = {
          categoria: [],
          tamanho: [],
          cor: [],
          ocasiao: [],
          preco: { min: null, max: null },
          marca: [],
        };
      } else {
        updatedFilters = { ...prevFilters };
      }
      const addFilterIfNotExists = (filterType, foundFilter) => {
        if (foundFilter !== undefined && searchFilter !== "" && busca !== true) {
          const filterList = updatedFilters[filterType];
          if (!filterList.includes(foundFilter.description)) {
            updatedFilters = {
              ...updatedFilters,
              [filterType]: [...filterList, foundFilter.description],
            };
          }
        }
      };
      addFilterIfNotExists("tamanho", foundSize);
      addFilterIfNotExists("cor", foundColor);
      addFilterIfNotExists("categoria", foundGroup);
      addFilterIfNotExists("marca", foundBrand);
      addFilterIfNotExists("ocasiao", foundOcasiao);
      if (searchFilter !== "" && (searchFilter !== prevSearchFilter)) {
        updatedFilters = {
          categoria: [],
          tamanho: [],
          cor: [],
          ocasiao: [],
          preco: { min: null, max: null },
          marca: [],
        };
        addFilterIfNotExists("tamanho", foundSize);
        addFilterIfNotExists("cor", foundColor);
        addFilterIfNotExists("categoria", foundGroup);
        addFilterIfNotExists("marca", foundBrand);
        addFilterIfNotExists("ocasiao", foundOcasiao);
      }
      return updatedFilters;

    });

    setPrevSearchFilter(searchFilter);
  }, [brands, colors, groups, ocasioes, searchFilter, sizes, prevSearchFilter, busca]);

  const [openPanel, setOpenPanel] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  const renderCurrency = (value) => {
    if (value !== null) {
      const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
      return `${formattedValue}`;
    }
    return "R$ 0";
  };

  const categorias = Array.from(
    new Set(groups.map((group) => group.description))
  );
  const tamanhos = Array.from(new Set(sizes.map((size) => size.description)));

  const cores = Array.from(new Set(colors.map((color) => color.description)));

  const ocasiao = Array.from(
    new Set(ocasioes.map((ocasiao) => ocasiao.description))
  );

  // const ocasioes = Array.from(
  //   new Set(products.map((product) => product.ocasiao))
  // ).filter((ocasiao) => ocasiao !== null && ocasiao !== undefined);

  const marcas = Array.from(new Set(brands.map((brand) => brand.description)));

  const renderCheckboxes = (filterName, filterValues) => {
    const filteredValues = filterItems(filterValues, filterName);
    // const searchTermLower = searchTerms[filterName].toLowerCase();

    return (
      <SubmenuScrollable>
        <SearchInputFilter
          type="text"
          placeholder={`Pesquisar ${filterName}`}
          value={searchTerms[filterName]}
          onChange={(e) => handleSearch(e, filterName)}
        />
        {filteredValues.map((filterValue) => {

          return (
            <CheckboxLabel key={filterValue}>
              <CheckboxInput
                type="checkbox"
                value={filterValue}
                checked={
                  selectedFilters[filterName].includes(filterValue)
                }
                onChange={(event) => handleCheckboxChange(event, filterName)}
                tintColors={{ true: "#FFF" }}
              />
              {filterValue}
            </CheckboxLabel>
          );
        })}
      </SubmenuScrollable>
    );
  };
  const FilterDropdown = ({
    label,
    options,
    filterKey,
    selectedFilters,
    handleCheckboxChange,
  }) => {
    const [visibleItems, setVisibleItems] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    const handleShowMore = () => {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
    };

    const filteredOptions = options.filter((filterValue) =>
      filterValue.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <label style={{ fontWeight: "700", marginRight: "10px" }}>
            {label}
          </label>
          <input
            type="text"
            placeholder="Pesquisar... "
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              borderRadius: "25px",
              border: "1px #ccc solid",
              width: "100px",
              height: "30px",
              paddingLeft: "10px"
            }}
          />
        </div>

        {filteredOptions.slice(0, visibleItems).map((filterValue) => (
          <div key={filterValue}>
            <CheckboxLabel>
              <CheckboxInput
                type="checkbox"
                value={filterValue}
                checked={selectedFilters[filterKey].includes(filterValue)}
                onChange={(event) => handleCheckboxChange(event, filterKey)}
                tintColors={{ true: "#FFF" }}
              />
              {filterValue}
            </CheckboxLabel>
          </div>
        ))}

        {filteredOptions.length > visibleItems && (
          <ButtonFilter
            style={{
              width: "50px",
              height: "5px",
              alignItems: "center",
              display: "flex",
              margin: "0",
              marginTop: "10px",
            }}
            onClick={handleShowMore}
          >
            Ver mais
          </ButtonFilter>
        )}
        <br />
      </div>
    );
  };
  return (
    <>
      <FilterContainer>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("categoria")}>
            Categorias{" "}
            {activeSection === "categoria" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "categoria" &&
            renderCheckboxes("categoria", categorias)}
        </ListaFiltro>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("tamanho")}>
            Tamanho{" "}
            {activeSection === "tamanho" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "tamanho" && renderCheckboxes("tamanho", tamanhos)}
        </ListaFiltro>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("cor")}>
            Cor{" "}
            {activeSection === "cor" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "cor" && renderCheckboxes("cor", cores)}
        </ListaFiltro>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("ocasiao")}>
            Ocasião{" "}
            {activeSection === "ocasiao" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "ocasiao" && renderCheckboxes("ocasiao", ocasiao)}
        </ListaFiltro>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("marca")}>
            Marca{" "}
            {activeSection === "marca" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "marca" && renderCheckboxes("marca", marcas)}
        </ListaFiltro>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("preco")}>
            Preço{" "}
            {activeSection === "preco" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "preco" && (
            <SubmenuScrollable>
              <CheckboxLabel>
                Min: {renderCurrency(priceRange.min)}
              </CheckboxLabel>
              <div>
                <SearchInputFilter
                  type="text"
                  value={priceRange.min > 100000 ? '100000' : priceRange.min}
                  onChange={(e) => {
                    const numericValue = parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0;
                    setPriceRange({
                      ...priceRange,
                      min: Math.min(numericValue, 100000),
                    });
                  }}
                />
                <RangeInput
                  type="range"
                  min={100}
                  max={100000}
                  step={300}
                  value={priceRange.min}
                  onChange={(e) =>
                    setPriceRange({
                      ...priceRange,
                      min: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
              <CheckboxLabel>
                Max: {renderCurrency(priceRange.max)}
              </CheckboxLabel>
              <div>
                <SearchInputFilter
                  type="text"
                  value={priceRange.max > 100000 ? '100000' : priceRange.max}
                  onChange={(e) => {
                    const numericValue = parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0;
                    setPriceRange({
                      ...priceRange,
                      max: Math.min(numericValue, 100000),
                    });
                  }}
                />
                <RangeInput
                  type="range"
                  min={100}
                  max={100000}
                  step={300}
                  value={priceRange.max}
                  onChange={(e) =>
                    setPriceRange({
                      ...priceRange,
                      max: parseFloat(e.target.value),
                    })
                  }
                />
              </div>
              <ButtonPrice onClick={() => handlePrecoChange(priceRange)}>
                Aplicar Faixa de Preço
              </ButtonPrice>
            </SubmenuScrollable>
          )}

        </ListaFiltro>
        <ButtonFilter onClick={() => setOpenPanel(!openPanel)}>
          VER TODOS OS FILTROS <Icon as={BsChevronRight}></Icon>
        </ButtonFilter>
        <ListaFiltro>
          <Categorias onClick={() => toggleSection("orderBy")}>
            Ordenar por{" "}
            {activeSection === "orderBy" ? (
              <Icon as={BsChevronUp} />
            ) : (
              <Icon as={BsChevronDown} />
            )}
          </Categorias>
          {activeSection === "orderBy" && (
            <SubmenuScrollable>
              <CheckboxLabel>
                <input
                  type="radio"
                  name="ordenarPor"
                  value="asc"
                  checked={opcaoSelecionada === "asc"}
                  onChange={() => handleOrdenacaoChange("asc")}
                />
                A-Z
              </CheckboxLabel>
              <CheckboxLabel>
                <input
                  type="radio"
                  name="ordenarPor"
                  value="desc"
                  checked={opcaoSelecionada === "desc"}
                  onChange={() => handleOrdenacaoChange("desc")}
                />
                Z-A
              </CheckboxLabel>
              <CheckboxLabel>
                <input
                  type="radio"
                  name="ordenarPor"
                  value="price_asc"
                  checked={opcaoSelecionada === "price_asc"}
                  onChange={() => handleOrdenacaoChange("price_asc")}
                />
                Menor Preço <BsArrowDown></BsArrowDown>
              </CheckboxLabel>
              <CheckboxLabel>
                <input
                  type="radio"
                  name="ordenarPor"
                  value="price_desc"
                  checked={opcaoSelecionada === "price_desc"}
                  onChange={() => handleOrdenacaoChange("price_desc")}
                />
                Maior Preço <BsArrowUp></BsArrowUp>
              </CheckboxLabel>
            </SubmenuScrollable>
          )}
        </ListaFiltro>
      </FilterContainer>
      <div>
        <SlidingPane
          closeIcon={<div>Filtrar Produto.</div>}
          isOpen={openPanel}
          width="300px"
          onRequestClose={() => setOpenPanel(false)}
        >
          <div>
            <FilterDropdown
              label="Categorias"
              options={categorias}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              filterKey="categoria"
              selectedFilters={selectedFilters}
              handleCheckboxChange={handleCheckboxChange}
            />
            <FilterDropdown
              label="Tamanhos"
              options={tamanhos}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              filterKey="tamanho"
              selectedFilters={selectedFilters}
              handleCheckboxChange={handleCheckboxChange}
            />
            <FilterDropdown
              label="Cores"
              options={cores}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              filterKey="cor"
              selectedFilters={selectedFilters}
              handleCheckboxChange={handleCheckboxChange}
            />
            <FilterDropdown
              label="Ocasiões"
              options={ocasiao}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              filterKey="ocasiao"
              selectedFilters={selectedFilters}
              handleCheckboxChange={handleCheckboxChange}
            />
            <FilterDropdown
              label="Marcas"
              options={marcas}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              filterKey="marca"
              selectedFilters={selectedFilters}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        </SlidingPane>
      </div>
    </>
  );
};

export default Filter;
