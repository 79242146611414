// import React, { useState, useEffect } from "react";
import React, { useEffect } from "react";
import { BackgroundImage, ShopNow, Title, TitleDiv } from "./styles";
// import Newsletter from "../Newsletter";
import { useNavigate } from "react-router-dom";
import useImageProgressive from "hooks/useImageProgressive";
const Banner = () => {
  const [image1] = useImageProgressive("assets/imgs/downgrade/chapeu_vestido.jpg", "assets/imgs/chapeu_vestido.jpg");

  const navigate = useNavigate();
  // const [isOpenNew, setIsOpenNew] = useState(true);

  // const handleNewsletterClose = () => {
  //   setIsOpenNew(false);
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const shopNowButton = document.getElementById("bannerShopNowButton");
    shopNowButton.addEventListener("click", scrollToTop);

    return () => {
      shopNowButton.removeEventListener("click", scrollToTop);
    };
  }, []);

  return (
    <BackgroundImage image={image1}>
      <TitleDiv>
        <Title>TAKE ME VINTAGE</Title>
        <br />
        <ShopNow id="bannerShopNowButton" onClick={() => navigate("/shop")}>
          SHOP NOW
        </ShopNow>
      </TitleDiv>
      {/* <Newsletter isOpen={isOpenNew} onClose={handleNewsletterClose} /> */}
    </BackgroundImage>
  );
};

export default Banner;
