import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  background-color: #f7f4ec;
  padding-top:40px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 0px;
    gap: 0px;
  }
`;
export const Header = styled.div`
  padding: 0 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ec;
  ${({ border }) => (border ? "border-bottom: 1px solid #000" : "")}
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 35px 50px;

  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }
`;

export const MenuItem = styled.a`
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    padding: 5px 20px;
    border-top: 1px solid #000;
  }
`;

export const Section = styled.div`
  width: 90%;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Title2 = styled.h2`
  font-size: 20px;
  font-weight: normal;
  margin-top:-30px;
  @media (max-width: 768px) {
    font-size: 10px;
    margin-top:-15px;
  }
`;


export const Text = styled.p`
  font-size: 24px;
  padding-bottom:20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
