import styled from "styled-components";
export const Title = styled.span`
  font-weight: 400;
  font-size: 22px;
`;

export const CenteredDiv = styled.div`
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  width: 300px;
  height: 35px;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  border: 1px solid rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 50px;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font: normal normal normal 12px ${(props) => props.theme.font.family};
`;

export const boxQRCode = styled.div`
 
`;