export const brandsMobile = [
    {
      title: "Marcas1",
      items: [
        { text: "Alaïa", cod_interno: "ALAIÁ"},
        { text: "Bottega Veneta", cod_interno: "BOTTEGA VENETA"},
        { text: "Burberry", cod_interno: "BURBERRY"},
        { text: "Chanel", cod_interno: "CHANEL"},
        { text: "Chloé", cod_interno: "CHLOÉ"},
        { text: "Christian Dior", cod_interno: "CHRISTIAN DIOR"},
        { text: "Christian Louboutin", cod_interno: "CHRISTIAN LOUBOUTIN"},
        { text: "Diane Von Furstenberg", cod_interno: "DIANE VON FURSTENBER"},
        { text: "Dolce & Gabbana", cod_interno: "DOLCE & GABBANA"},
        { text: "Emilio Pucci", cod_interno: "EMILIO PUCCI"},
        { text: "Fendi", cod_interno: "FENDI"},
        { text: "Gianvito Rossi", cod_interno: "GIANVITO ROSSI"},
        { text: "Gucci", cod_interno: "GUCCI"},
        { text: "Hermès", cod_interno: "HERMÈS"},
        { text: "Louis Vuitton", cod_interno: "LOUIS VUITTON"},
        { text: "Missoni", cod_interno: "MISSONI"},
        { text: "Miu Miu", cod_interno: "MIU MIU"},
        { text: "Paula Raia", cod_interno: "PAULA RAIA"},
        { text: "Prada", cod_interno: "PRADA"},
        { text: "Stella McCartney", cod_interno: "STELLA MCCARTNEY"},
        { text: "Ulla Johnson", cod_interno: "ULLA JOHNSON"},
        { text: "Valentino", cod_interno: "VALENTINO"},
        { text: "Zimmermann", cod_interno: "ZIMMERMANN"},
      ],
    }
  ];
  