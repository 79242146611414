import React, { useEffect, useState, useCallback } from "react";
import Layout from "containers/Layouts/Layout";
import { Container } from "./styles";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import configs from "config";

const ConfirmEmail = () => {
  const { token } = useParams();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get("email");

  const [resposta, setResposta] = useState("Aguardando confirmação...");

  const handleSubmit = useCallback(async () => {
    try {
      const resposta = await axios.post(
        configs.api + "confirm",
        {
          token,
          email,
        }
      );
      setResposta(resposta.data.msg);
    } catch (e) {
      const response = e.response ? e.response : {};
      const data = response.data ? response.data : {};
      const msg = data.message ? data.message : "Ocorreu um erro";
      setResposta(msg);
    }
  }, [token, email]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <Layout>
      <Container>
        <span>{ resposta }</span>
      </Container>
    </Layout>
  );
};

export default ConfirmEmail;
