import "./App.css";
import Routes from "./routes";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { CartProvider } from "context/CartContext";
import { AppProvider, initialState, reducer } from "context/app";
import { useEffect, useReducer } from "react";
import ReactGA from 'react-ga4';
import configs from "./config";


function App() {

  

  useEffect(() => {
    if(configs.ga_mesuarement){
      ReactGA.initialize(configs.ga_mesuarement);
      ReactGA.send({ hitType: "pageview", page: "/", title: "Takeme" });
    }
    
}, [])

  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ThemeProvider theme={theme}>
      <AppProvider value={{ state, dispatch }}>
        <CartProvider>
          <Routes />
        </CartProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
