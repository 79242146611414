import actions from "./actions";

const reducer = (state, action) => {
    switch(action.type){
        case actions.HANDLE_CHANGE:
            return {...state, [action.payload.name] : action.payload.value}
        case actions.HANDLE_MENU:
            return {...state, menuOpen : action.payload}
        default:
            return state;
    }
}

export default reducer;
