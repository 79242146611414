import styled from "styled-components";

export const ProductImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Imagem = styled.img`
  width: 100%;
  object-fit: contain;
  opacity: 1; 
  flex: 1; 
  min-height: 300px;
  transition: opacity 0.3s ease;

  ${ProductImageContainer}:hover & {
    opacity: 0;
    transition: opacity 1.5s ease; 
  }
`;

export const HoveredImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${ProductImageContainer}:hover & {
    opacity: 1;
    transition: opacity 1.5s ease; 
  }
`;