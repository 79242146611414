import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
`;

export const FormReset = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;

  span {
    font-size: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px;

    label {
      padding-bottom: 5px;
    }

    input {
      height: 35px;
      border-radius: 25px;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }

  @media (max-width: 768px) {
    width: 70%;

    label {
      font-size: 16px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  margin-top: 20px;
  display: flex;
  padding: 10px 30px;
  font-size: 14px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ec;
  
  &:hover {
    background-color: #d8dfc1;
  }
  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    padding: 15px;
  }
`;
