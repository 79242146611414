import React, { useState } from "react";
import { Form, SearchInput } from "./styles";
import { useNavigate } from "react-router-dom";

const Search = ({ onSearch, onClose }) => {
   const [searchTerm, setSearchTerm] = useState("");
   const navigate = useNavigate();
 
   const handleChange = (e) => {
     setSearchTerm(e.target.value);
   };
 
   const handleSubmit = (e) => {
     e.preventDefault();
     onSearch(searchTerm);
     navigate({
       pathname: '/shop',
       search: `?search=${searchTerm}&busca=true`,
     });
   };
 
   return (
     <Form onSubmit={handleSubmit} border>
       <SearchInput
         type="text"
         placeholder="Digite sua busca"
         value={searchTerm}
         onChange={handleChange}
       />
     </Form>
   );
};

export default Search;
