import styled from "styled-components";

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998; 
`;

export const Modal = styled.div`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255); 
  height: auto;
  width: 390px;
  padding: 10px;
  position: fixed;
  z-index: 9999;
  border-radius: 15px;
  border: 1px solid #000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width:768px){
    width: 230px;
  }
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  height: 35px;
  font-size: 14px;
  border: 1px solid #000;
  cursor: pointer;
  margin-bottom:20px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  font-family: Thasadith;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;
