import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  font: normal normal normal 18px ${(props) => props.theme.font.family};
  font-weight: 300;

 
`;
