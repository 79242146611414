import {
  Content,
  Menu,
  MenuItem,
  PageContainer,
  Section,
  Text,
  Title,
  Title2,
  Header,
  HeaderTitle,
} from "./styles";
import Layout from "../Layouts/Layout";
import Follow from "../../components/Follow";
import { useState } from "react";
import Button from "components/Button";
import { Link } from "react-router-dom";
import aboutUS from "../../assets/imgs/aboutUs.jpg";
const About = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveMenuItem(id);
    }
  };

  return (
    <Layout>
      <Header border>
        <HeaderTitle>ABOUT</HeaderTitle>
      </Header>

      <PageContainer border>
        <Button>
          <Link
            to="https://wa.me/message/APZHBOCKQT5SE1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#000" }}
            as="div"
          >
            AGENDE UM HORÁRIO
          </Link>
        </Button>
        <Menu>
          <div style={{ marginBottom: "40px", display: "flex", justifyContent: "center", alignContent: "center" , marginLeft: window.innerWidth < 768 ? "50px" : "0"}}>
            <img
              style={{ objectFit: "contain", width: "100%", height: "100%"}}
              src={aboutUS}
              alt="About Us"
            />
          </div>
          <MenuItem
            onClick={() => scrollToSection("section3")}
            isActive={activeMenuItem === "section3"}
          >
            Sobre as fundadoras
          </MenuItem>
          <MenuItem
            onClick={() => scrollToSection("section1")}
            isActive={activeMenuItem === "section1"}
          >
            Os três elementos Take Me
          </MenuItem>
          <MenuItem
            onClick={() => scrollToSection("section2")}
            isActive={activeMenuItem === "section2"}
          >
            Sobre as peças doadas
          </MenuItem>
         
        </Menu>
        <Content>
          <Section id="section3">
            <Title>About Us</Title>
            <Text>
              Ana Piva e Melissa Moraes acreditam que a boa moda atravessa gerações. Com a Take Me, as fundadoras mantêm vivas peças selecionadas com uma criteriosa curadoria, que valoriza o valor criativo do design de moda, seja ele um tesouro vintage ou criado na temporada passada. Ao sugerir um guarda-roupas que incorpora elementos que borram as linhas entre as temporadas, a dupla observa com olhar contemporâneo a importância da preservação e manutenção do uso de peças com qualidade excepcional e estilo atemporal – indo na contramão da produção e consumo excessivos de moda.
            </Text>
            <hr></hr>
          </Section>
          <Section id="section1">

            <Title>Take Me VINTAGE</Title>
            <Title2>Curadoria de moda à venda</Title2>
            <Text>
              A moda do futuro é aquela que já existe. Acreditamos na moda que dura mais de uma estação. Sem tendências passageiras. Sem produção excessiva. Sem impacto desnecessário. Através de uma curadoria única no mercado de luxo nacional e internacional, selecionamos roupas, bolsas, sapatos e acessórios que se mantêm mais vivos do que nunca.
            </Text>

            <Title>Take Me AROUND</Title>
            <Title2>Nosso serviço de aluguel</Title2>
            <Text>
              Momentos especiais duram para sempre. A moda bem feita, também. Percorra nossa seleção com milhares de roupas, bolsas, sapatos e acessórios prontos para ser o seu plus-one na próxima ocasião especial circulada no calendário. Através de nosso serviço de aluguel, montar um look inesquecível ficou tão simples como fazer o RSVP. Escolha, experimente, use e devolva quando quiser. Nós cuidamos do resto.
            </Text>

            <Title>Take Me SELECTION</Title>
            <Title2>Escolhas sob medida para você</Title2>
            <Text>
              Acreditamos que comprar moda de maneira assertiva também é uma forma de praticar o consumo consciente. Seja para uma viagem especial, uma festa importante, localizar o item que não sai da sua cabeça ou simplesmente para uma bem-vinda atualização de estilo, estamos prontas para ajudá-la a encontrar as peças certas, para a hora certa.
            </Text>
            <hr></hr>

          </Section>
          <Section id="section2">

            <Title>Give Me</Title>
            <Text>
              Dentro do acervo da Take Me, há peças cuja venda é inteiramente revertida para causas sociais. O processo é simples: doando uma peça, você escolhe a causa de sua preferência que recebe o valor integral quando o item for vendido. Em nossa comunidade de doadoras de moda, os nomes permanecem anônimos, jogando luz somente para a boa ação que está sendo feita.
            </Text>
            <hr></hr>

          </Section>

        </Content>
      </PageContainer>
      <Follow />
    </Layout>
  );
};

export default About;
