import React from "react";
import {
  Close,
  Container,
  Content,
  ContentTable,
  DivTitle,
  SubContainer,
  Table,
  TableCell,
  TableHeader,
  Title,
} from "./styles";

const ModalSize = ({ isOpen, onClose }) => {
  const countries = [
    { name: "UK", code: "UK", sizes: ["4", "6", "8", "10", "12", "14", "16"] },
    { name: "US", code: "US", sizes: ["0", "2", "4", "6", "8", "10", "12"] },
    {
      name: "IT",
      code: "IT",
      sizes: ["36", "38", "40", "42", "44", "46", "48"],
    },
    {
      name: "FR",
      code: "FR",
      sizes: ["32", "34", "36", "38", "40", "42", "44"],
    },
    { name: "JPN", code: "JPN", sizes: ["3", "5", "7", "9", "11", "13", "15"] },
    {
      name: "BR",
      code: "BR",
      sizes: ["34", "36", "38", "40", "42", "44", "46"],
    },
  ];

  const sizes = ["XXS", "XS", "S", "M", "M-L", "L", "XL"];

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Container>
      <SubContainer>
        <Close onClick={handleClose}> X </Close>
        <Content>
          <DivTitle>
            <Title>GUIA DE TAMANHOS</Title>
          </DivTitle>
          <ContentTable>
            <Table>
              <thead>
                <tr>
                  <TableHeader></TableHeader>
                  {sizes.map((size) => (
                    <TableHeader key={size}>{size}</TableHeader>
                  ))}
                </tr>
              </thead>
              <tbody>
                {countries.map((country) => (
                  <tr key={country.code}>
                    <TableCell>{country.name}</TableCell>
                    {country.sizes.map((size) => (
                      <TableCell key={size}>{size}</TableCell>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </ContentTable>
        </Content>
      </SubContainer>
    </Container>
  );
};

export default ModalSize;
