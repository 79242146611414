import {
  ImageContainer,
  Content,
  HeaderTitle,
  TextDiv,
  Header,
  Text,
  Boutique,
  ImageContainer2,
  HeaderSubTitle
} from "./styles";
import Follow from "../../components/Follow";
import Layout from "../Layouts/Layout";
import { useEffect } from "react";

const TakeMeAround = () => {


  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'boutique-script';
    script.src = 'https://widgets.rewardstyle.com/js/boutique.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <Content>
        <ImageContainer>
        
          <TextDiv>
            <Header>
              <HeaderTitle>SELECTION</HeaderTitle>
            </Header>
              <HeaderSubTitle>Escolhas sob medida para você</HeaderSubTitle>
            <Text>
            Acreditamos que comprar moda de maneira assertiva também é uma forma de praticar o consumo consciente. Seja para uma viagem especial, uma festa importante, localizar o item que não sai da sua cabeça ou simplesmente para uma bem-vinda atualização de estilo, estamos prontas para ajudá-la a encontrar as peças certas, para a hora certa.    
            </Text>
          
          </TextDiv>
        </ImageContainer>
       
        <ImageContainer2 border>
         
          <Boutique 
              className="boutique-widget" 
              data-widget-id="1262103"
              dangerouslySetInnerHTML={{ __html: `
                <div class="rs-adblock">
                  <img 
                    src="https://assets.rewardstyle.com/production/c085303e756da49e9b7f989d5291abeb834c0418/images/search/350.gif" 
                    onerror='this.parentNode.innerHTML="Disable your ad blocking software to view this content."'
                    style="width: 15px; height: 15px"
                  >
                  <noscript>JavaScript is currently disabled in this browser. Reactivate it to view this content.</noscript>
                </div>
              ` }}
            />
          </ImageContainer2>
      </Content>
      <Follow />
    </Layout>
  );
};

export default TakeMeAround;
